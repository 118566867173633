import { methods } from '@/constants';
import * as endpoints from '@/services/backend/snami/constants';

export const eventRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_EVENTS,
    ...payload,
  }),

  create: (payload) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_EVENT_CREATE,
    ...payload,
  }),

  update: (payload) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_EVENT_UPDATE,
    ...payload,
  }),
  delete: (payload) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_EVENT_DELETE,
    ...payload,
  }),

  getAnalytic: (id) => transport({
    method: methods.get,
    url: endpoints.API_CUSTOMER_EVENT_ANALYTICS(id),
  }),
});

export default eventRepository;
