import { methods } from '@/constants';

const API_ADMIN_CHANGELOG = '/v2/admin/changelog';
const API_ADMIN_CHANGELOG_ITEM = (id) => `/v2/admin/changelog/${id}`;

export const adminChangelogRepository = (transport) => ({
  create: (payload = {}) => transport({
    method: methods.post,
    url: API_ADMIN_CHANGELOG,
    ...payload,
  }),
  list: (payload = {}) => transport({
    method: methods.get,
    url: API_ADMIN_CHANGELOG,
    ...payload,
  }),
  get: (payload = {}) => transport({
    method: methods.get,
    url: API_ADMIN_CHANGELOG_ITEM(payload.id),
  }),
  update: (payload = {}) => transport({
    method: methods.put,
    url: API_ADMIN_CHANGELOG_ITEM(payload?.data?.id),
    ...payload,
  }),
  delete: (payload = {}) => transport({
    method: methods.delete,
    url: API_ADMIN_CHANGELOG_ITEM(payload?.data?.id),
  }),
});
export default adminChangelogRepository;
