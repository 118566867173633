import localStorageInterface from '@/services/localStorage/_utils';
import crypto from 'crypto';

let isInit = false;

const usedesk = (profile, additional_fields = [], callbackClick = () => {}) => {
  if (!isInit) {
    const script = document.createElement('script');
    const accessTokenValue = localStorageInterface('user_token').get()
      .split('.');
    const tokenData = JSON.parse(atob(accessTokenValue[1]));

    const fio = `${profile.last_name} ${profile.middle_name} ${profile.first_name}`;

    const currentApplicationHost = window.location.hostname.split('.')
      .slice(1)
      .join('.')
      || 'localhost';

    const token = crypto.createHash('sha256')
      .update(`${tokenData.user_id}_${currentApplicationHost}`)
      .digest('hex');

    const identity = {
      name: fio,
      email: profile.login || '',
      phone: profile.phoneNumber || '',
      token,
      additional_fields,
    };

    // eslint-disable-next-line no-underscore-dangle
    window.__widgetInitCallback = (widget) => {
      widget.identify(identity);
      const usedeskEl = document.querySelector('#usedesk-messenger');
      if (usedeskEl && typeof callbackClick === 'function') {
        usedeskEl.addEventListener('click', () => {
          callbackClick();
        });
      }
    };

    script.src = process.env.VUE_APP_USEDESK_SCRIPT;
    document.body.appendChild(script);

    isInit = true;
  }
};

export default usedesk;
