import * as names from '@/plugins/vue-router/constants';
import isEnableMt from '@/plugins/vue-router/guards/isEnableMt';

export const routesReport = [
  {
    path: '',
    name: names.R_REPORTS,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesReports" */ '@/views/Reports.vue'),
      'tt-view-right': () => import('@/components/app/AppDefaultAsideRight.vue'),
    },
  },
  {
    path: 'meeting',
    name: names.R_REPORT_MEETING,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesReportMeeting" */ '@/views/Reports/ReportMeeting.vue'),
      'tt-view-right': () => import('@/components/app/AppReportMeetingAsideRight.vue'),
    },
  },
  {
    path: 'tutor',
    name: names.R_REPORT_TUTOR_LIST,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesReportTutor" */ '@/views/Tutor/ReportTutor.vue'),
      'tt-view-right': () => import('@/components/app/AppReportTutorAsideRight.vue'),
    },
  },
  {
    path: 'progress',
    name: names.R_REPORT_PROGRESS,
    meta: {
      middleware: [isEnableMt],
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesReportProgress" */ '@/views/Reports/ReportProgress.vue'),
      'tt-view-right': () => import('@/components/app/AppReportProgressAsideRight.vue'),
    },
  },
  {
    path: 'staff',
    name: names.R_REPORT_STAFF,
    meta: {
      middleware: [isEnableMt],
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesReportStaff" */ '@/views/Reports/ReportStaff.vue'),
      'tt-view-right': () => import('@/components/app/AppReportStaffAsideRight.vue'),
    },
  },
];
export default routesReport;
