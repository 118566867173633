import {
  R_GROUP, R_GROUP_CREATE, R_GROUP_MANAGEMENT, R_GROUPS,
} from '@/plugins/vue-router/constants';

export const routesGroups = [
  {
    path: '',
    name: R_GROUPS,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesGroups" */ '@/views/Groups/Groups.vue'),
      // eslint-disable-next-line max-len,vue/max-len
      'tt-view-right': () => import(/* webpackChunkName: "routesGroupsAsideRight" */ '@/components/app/AppGroupsAsideRight.vue'),

    },
  },
  {
    path: 'create',
    name: R_GROUP_CREATE,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesGroupsManagement" */ '@/views/Groups/GroupsManagement.vue'),
      // eslint-disable-next-line max-len,vue/max-len
      'tt-view-right': () => import(/* webpackChunkName: "routesGroupsAsideRight" */ '@/components/app/AppGroupsAsideRight.vue'),

    },
  },
  {
    path: ':id',
    name: R_GROUP,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesGroupsId" */ '@/views/Groups/GroupId.vue'),
      // eslint-disable-next-line max-len,vue/max-len
      'tt-view-right': () => import(/* webpackChunkName: "routesGroupsAsideRight" */ '@/components/app/AppGroupsAsideRight.vue'),

    },
  },
  {
    path: ':id/management',
    name: R_GROUP_MANAGEMENT,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesGroupsIdManagement" */ '@/views/Groups/GroupsManagement.vue'),
      // eslint-disable-next-line max-len,vue/max-len
      'tt-view-right': () => import(/* webpackChunkName: "routesGroupsAsideRight" */ '@/components/app/AppGroupsAsideRight.vue'),
    },
  },

];
export default routesGroups;
