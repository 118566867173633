import * as names from '@/plugins/vue-router/constants';

export const routesPotok = [
  {
    path: 'oauth/callback',
    name: names.R_POTOK_OAUTH,
    component: () => import(/* webpackChunkName: "routesPotok" */ '@/views/Potok/Oauth.vue'),
  },
];
export default routesPotok;
