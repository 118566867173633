import { methods } from '@/constants';
import {
  API_CUSTOMER_LOCATIONS,
  API_CUSTOMER_LOCATIONS_CREATE,
  API_CUSTOMER_LOCATIONS_DELETE,
  API_CUSTOMER_LOCATIONS_UPDATE,
} from '@/services/backend/snami/constants';

export const locationRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_LOCATIONS,
    data: payload,
  }),
  create: (payload) => transport({
    method: methods.post,
    url: API_CUSTOMER_LOCATIONS_CREATE,
    data: payload,
  }),
  update: (payload) => transport({
    method: methods.post,
    url: API_CUSTOMER_LOCATIONS_UPDATE,
    data: payload,
  }),
  delete: (id) => transport({
    method: methods.post,
    url: API_CUSTOMER_LOCATIONS_DELETE,
    data: { id },
  }),
});

export default locationRepository;
