import { R_ABOUT } from '@/plugins/vue-router/constants';

export const routesAbout = [
  {
    path: '',
    name: R_ABOUT,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesAbout" */ '@/views/About/About.vue'),
    },
  },
];
export default routesAbout;
