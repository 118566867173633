import { methods } from '@/constants';
import {
  API_CUSTOMER_TASK_COPY,
  API_CUSTOMER_TASK_CREATE,
  API_CUSTOMER_TASK_DELETE,
  API_CUSTOMER_TASK_LIST,
  API_CUSTOMER_TASK_MOVE, API_CUSTOMER_TASK_REORDER, API_CUSTOMER_TASK_UPDATE,
} from '@/services/backend/snami/constants';

export const taskRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_LIST,
    ...payload,
  }),

  delete: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_DELETE,
    ...payload,
  }),

  move: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_MOVE,
    ...payload,
  }),

  create: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_CREATE,
    ...payload,
  }),

  update: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_UPDATE,
    ...payload,
  }),

  copy: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_COPY,
    ...payload,
  }),

  reorder: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_REORDER,
    ...payload,
  }),
});

export default taskRepository;
