import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import { Vue as Integrations } from '@sentry/integrations';

const { VUE_APP_SENTRY_DSN = '', VUE_APP_SENTRY_ENV = 'development' } = process.env;

if (VUE_APP_SENTRY_DSN) {
  Sentry.init({
    environment: VUE_APP_SENTRY_ENV,
    dsn: VUE_APP_SENTRY_DSN,
    integrations: [
      new Integrations({
        Vue,
        attachProps: true,
        logErrors: true,
      }),
    ],
  });
}
