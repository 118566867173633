import dayjs from 'dayjs';

// eslint-disable-next-line import/prefer-default-export
export const maxDateISO = (dates) => dayjs.max(dates.map((item) => dayjs(item))).toISOString();

export const isAfterDate = (newDate, oldDate, unit = '') => dayjs(newDate).isAfter(oldDate, unit);

export const dateFormat = (date, format = 'LL') => dayjs(date).format(format);

export const dateToISO = (date = dayjs()) => dayjs(date).toISOString();
