/* eslint-disable prefer-promise-reject-errors,func-names */

const set = (tokenName) => (payload) => localStorage.setItem(tokenName, JSON.stringify(payload));
const get = (tokenName) => () => {
  try {
    return JSON.parse(localStorage.getItem(tokenName));
  } catch (err) {
    console.error('Something wrong: ', err);
    return null;
  }
};
const remove = (tokenName) => () => localStorage.removeItem(tokenName);

const localStorageInterface = function (tokenName) {
  return {
    set: set(tokenName),
    get: get(tokenName),
    remove: remove(tokenName),
  };
};

export default localStorageInterface;
