var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VNavigationDrawer',{ref:"menu",staticClass:"tt-module-menu",class:{'tt-module-menu--is-mouseover' : _vm.drawer},style:(_vm.menuWidth),attrs:{"app":"","clipped":"","width":_vm.minWidth,"data-test":_vm.dataTest,"data-test-label":_vm.dataTestLabel,"data-test-value":_vm.dataTestValue,"mobile-breakpoint":"0"},nativeOn:{"mouseenter":function($event){_vm.mouseOut = false},"mouseleave":function($event){_vm.mouseOut = true}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('TTTooltip',{attrs:{"content-class":"tt-module-menu-item-tooltip","nudge-right":"4","right":"","open-delay":"300","fixed":"","disabled":_vm.drawer,"transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!_vm.hideSignOut)?_c('VBtn',_vm._g(_vm._b({staticClass:"tt-module-menu-sign-out",attrs:{"text":"","width":"64","height":"48","block":"","ripple":false,"data-test":"tt-btn","data-test-value":"exit"},on:{"click":function($event){return _vm.$emit('sign-out')}}},'VBtn',attrs,false),on),[_c('VIcon',{staticClass:"tt-module-menu-sign-out__icon"},[_vm._v(" fal fa-sign-out-alt ")]),(_vm.drawer)?_c('div',{staticClass:"tt-text-caption ml-3 text-left",style:(_vm.titleWidth)},[(_vm.userName)?_c('div',{staticClass:"text-truncate"},[_vm._v(" "+_vm._s(_vm.userName)+" ")]):_vm._e(),_c('div',{staticClass:"tt-light-red--text"},[_vm._v(" "+_vm._s(_vm.signOutText)+" ")])]):_vm._e()],1):_vm._e()]}}])},[_c('span',{staticClass:"tt-text-caption"},[_vm._v(_vm._s(_vm.signOutText))])])]},proxy:true}])},[_c('VFadeTransition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.drawer),expression:"!drawer"}],staticClass:"tt-module-menu__expand-menu-arrow",attrs:{"data-test":"expand-menu"},on:{"mouseenter":function($event){_vm.drawer=true}}},[_c('VIcon',{staticClass:"arrow-right tt-light-mono-46--text"},[_vm._v(" fal fa-angle-right ")])],1)]),_c('div',{staticClass:"tt-module-menu-groups"},[_vm._l((_vm.menuItems),function(groups,i){return [(i!==0)?_c('VDivider',{key:_vm.generateId(("group-divider-" + i)),staticClass:"mx-1"}):_vm._e(),_c('div',{key:_vm.generateId(("group-" + i)),staticClass:"tt-module-menu-group"},_vm._l((groups),function(item){return _c('TTTooltip',{key:_vm.generateId(("group-" + (item.title))),attrs:{"content-class":"tt-module-menu-item-tooltip","nudge-right":"4","right":"","close-delay":300,"open-delay":300,"disabled":_vm.drawer || !!item.isParent || !(item.tooltip || item.title),"transition":"fade-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('VMenu',{attrs:{"right":"","open-on-hover":"","open-on-click":"","offset-x":"","nudge-top":"4","disabled":!item.isParent,"close-on-content-click":"","transition":"fade-transition","content-class":"tt-module-menu-item-children__wrapper"},on:{"input":_vm.childrenMenuShowing},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var menuAttrs = ref.attrs;
var value = ref.value;
return [_c('VBtn',_vm._g(_vm._b({staticClass:"tt-module-menu-item",class:{
                    'tt-module-menu-item__parent' : item.isParent,
                    'v-btn--active' : _vm.getActiveValue(item, value)
                  },attrs:{"text":"","height":"48","to":item.to,"href":item.href,"target":item.target,"exact":item.exact,"data-test":"tt-module-menu-btn","data-test-value":item.testValue,"data-test-label":item.testLabel,"disabled":item.disabled,"block":"","ripple":false},on:{"click":function($event){return _vm.$emit('click', item)}}},'VBtn',Object.assign({}, tooltipAttrs, menuAttrs),false),Object.assign({}, tooltip, menu)),[_c('BadgeMenu',{attrs:{"badge":item.badge}},[_c('VIcon',[_vm._v(_vm._s(item.icon))])],1),(_vm.drawer)?_c('span',{staticClass:"tt-text-caption ml-3 text-truncate text-left",style:(_vm.titleWidth)},[_vm._v(_vm._s(item.tooltip || item.title))]):_vm._e(),(item.isParent)?_c('VIcon',{staticClass:"arrow-right tt-light-mono-46--text"},[_vm._v(" fal fa-angle-right ")]):_vm._e()],1)]}}],null,true)},[_c('div',{staticClass:"tt-module-menu-item-children__list"},_vm._l((item.children),function(children){return _c('VBtn',{key:_vm.generateId(("children-" + (children.title))),staticClass:"tt-module-menu-item",attrs:{"text":"","height":"48","to":children.to,"href":children.href,"target":children.target,"exact":children.exact,"ripple":false,"data-test":"tt-module-menu-btn-children","data-test-value":children.testValue,"data-test-label":children.testLabel},on:{"click":function($event){return _vm.$emit('click', children)}}},[_c('BadgeMenu',{attrs:{"badge":children.badge}},[_c('VIcon',[_vm._v(" "+_vm._s(children.icon)+" ")])],1),_c('span',{staticClass:"pl-3 text-caption"},[_vm._v(_vm._s(children.tooltip || children.title))])],1)}),1)])]}}],null,true)},[_c('span',{staticClass:"tt-text-caption"},[_vm._v(_vm._s(item.tooltip || item.title))])])}),1)]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }