













import Vue from 'vue';
import { VChipGroup } from 'vuetify/lib';

export default Vue.extend({
  name: 'TTChipGroup',
  mixins: [VChipGroup],
  props: {
    dataTest: {
      type: String,
      default: 'tt-chip-group',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
  },
});
