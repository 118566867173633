/* eslint-disable no-param-reassign */
import * as featuresApi from '@/services/api/features';

const state = {
  flags: [],
};

const getters = {
  flags: (st) => st.flags,
};

const actions = {
  async getFlags({ commit }) {
    try {
      const { features: flags } = await featuresApi.featuresGet();
      commit('M_SET_FLAGS', flags);
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  M_SET_FLAGS(st, payload) {
    st.flags = payload;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
