let isInit = false;
export const helpDesk = (profile, src) => {
  let script;

  if (!isInit) {
    script = document.createElement('script');

    script.src = src;
    script.setAttribute('data-lang', 'ru');
    script.setAttribute('id', 'hde-chat-widget');
    script.setAttribute('data-host', 'servicedesk.potok.io');
    script.setAttribute('data-position', 'bottom-right');
    script.setAttribute('defer', 'defer');
    document.body.appendChild(script);

    isInit = true;
  }

  if (script) {
    const fio = `${profile.last_name} ${profile.middle_name} ${profile.first_name}`;
    const email = profile.login || '';
    script.setAttribute('data-user-name', fio);
    script.setAttribute('data-user-email', email);
  }
};

export default helpDesk;
