import * as names from '@/plugins/vue-router/constants';

export const routesLevel = [
  {
    path: '',
    name: names.R_LEVEL,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesLevelList" */ '@/views/Level.vue'),
      'tt-view-right': () => import('@/components/app/AppLevelAsideRight.vue'),
    },
  },
  {
    path: ':id',
    name: names.R_LEVEL_ID,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesLevel" */ '@/views/Level.vue'),
      'tt-view-right': () => import('@/components/app/AppLevelAsideRight.vue'),
    },
  },
  {
    path: ':id/tasks',
    name: names.R_TASK_LEVEL,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesLevelTasks" */ '@/views/Task/TaskList.vue'),
      'tt-view-right': () => import('@/components/app/AppTaskListAsideRight.vue'),
    },
  },
];
export default routesLevel;
