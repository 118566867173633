import { render, staticRenderFns } from "./TTModuleMenu.vue?vue&type=template&id=c0452318&scoped=true&"
import script from "./TTModuleMenu.vue?vue&type=script&lang=js&"
export * from "./TTModuleMenu.vue?vue&type=script&lang=js&"
import style0 from "./TTModuleMenu.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./TTModuleMenu.scss?vue&type=style&index=1&id=c0452318&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c0452318",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VBtn,VDivider,VFadeTransition,VIcon,VMenu,VNavigationDrawer})
