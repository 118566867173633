import { methods } from '@/constants';
import {
  API_CUSTOMER_CONTENT_REPORT_DETAIL,
  API_CUSTOMER_CONTENT_REPORT_LIST,
  API_CUSTOMER_REPORT_CHECKPOINT,
  API_CUSTOMER_REPORT_EVENT,
  API_CUSTOMER_REPORT_PROGRESS,
  API_CUSTOMER_REPORT_STAFF,
} from '@/services/backend/snami/constants';

export const reportRepository = (transport) => ({
  getReportStaff: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_REPORT_STAFF,
    ...payload,
  }),

  getReportEvent: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_REPORT_EVENT,
    ...payload,
  }),

  getReportProgress: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_REPORT_PROGRESS,
    ...payload,
  }),
  getReportCheckpoint: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_REPORT_CHECKPOINT,
    ...payload,
  }),

  getTaskContentReportList: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONTENT_REPORT_LIST,
    ...payload,
  }),

  getTaskContentReportDetail: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONTENT_REPORT_DETAIL,
    ...payload,
  }),
});

export default reportRepository;
