<template>
  <VNavigationDrawer
    ref="menu"
    app
    clipped
    class="tt-module-menu"
    :class="{'tt-module-menu--is-mouseover' : drawer}"
    :width="minWidth"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
    mobile-breakpoint="0"
    :style="menuWidth"
    @mouseenter.native="mouseOut = false"
    @mouseleave.native="mouseOut = true"
  >
    <VFadeTransition>
      <div
        v-show="!drawer"
        class="tt-module-menu__expand-menu-arrow"
        data-test="expand-menu"
        @mouseenter="drawer=true"
      >
        <VIcon
          class="arrow-right tt-light-mono-46--text"
        >
          fal fa-angle-right
        </VIcon>
      </div>
    </VFadeTransition>
    <div class="tt-module-menu-groups">
      <template v-for="(groups, i) in menuItems">
        <VDivider
          v-if="i!==0"
          :key="generateId(`group-divider-${i}`)"
          class="mx-1"
        />
        <div
          :key="generateId(`group-${i}`)"
          class="tt-module-menu-group"
        >
          <TTTooltip
            v-for="(item) in groups"
            :key="generateId(`group-${item.title}`)"
            content-class="tt-module-menu-item-tooltip"
            nudge-right="4"
            right
            :close-delay="300"
            :open-delay="300"
            :disabled="drawer || !!item.isParent || !(item.tooltip || item.title)"
            transition="fade-transition"
          >
            <template v-slot:activator="{ on : tooltip, attrs : tooltipAttrs }">
              <VMenu
                right
                open-on-hover
                open-on-click
                offset-x
                nudge-top="4"
                :disabled="!item.isParent"
                close-on-content-click
                transition="fade-transition"
                content-class="tt-module-menu-item-children__wrapper"
                @input="childrenMenuShowing"
              >
                <template v-slot:activator="{ on : menu, attrs : menuAttrs, value }">
                  <VBtn
                    class="tt-module-menu-item"
                    :class="{
                      'tt-module-menu-item__parent' : item.isParent,
                      'v-btn--active' : getActiveValue(item, value)
                    }"
                    v-bind="{ ...tooltipAttrs, ...menuAttrs }"
                    text
                    height="48"
                    :to="item.to"
                    :href="item.href"
                    :target="item.target"
                    :exact="item.exact"
                    data-test="tt-module-menu-btn"
                    :data-test-value="item.testValue"
                    :data-test-label="item.testLabel"
                    :disabled="item.disabled"
                    block
                    :ripple="false"
                    v-on="{ ...tooltip, ...menu }"
                    @click="$emit('click', item)"
                  >
                    <BadgeMenu
                      :badge="item.badge"
                    >
                      <VIcon>{{ item.icon }}</VIcon>
                    </BadgeMenu>
                    <span
                      v-if="drawer"
                      class="tt-text-caption ml-3 text-truncate text-left"
                      :style="titleWidth"
                    >{{ item.tooltip || item.title }}</span>
                    <VIcon
                      v-if="item.isParent"
                      class="arrow-right tt-light-mono-46--text"
                    >
                      fal fa-angle-right
                    </VIcon>
                  </VBtn>
                </template>
                <div class="tt-module-menu-item-children__list">
                  <VBtn
                    v-for="(children) in item.children"
                    :key="generateId(`children-${children.title}`)"
                    class="tt-module-menu-item"
                    text
                    height="48"
                    :to="children.to"
                    :href="children.href"
                    :target="children.target"
                    :exact="children.exact"
                    :ripple="false"
                    data-test="tt-module-menu-btn-children"
                    :data-test-value="children.testValue"
                    :data-test-label="children.testLabel"
                    @click="$emit('click', children)"
                  >
                    <BadgeMenu
                      :badge="children.badge"
                    >
                      <VIcon>
                        {{ children.icon }}
                      </VIcon>
                    </BadgeMenu>

                    <span class="pl-3 text-caption">{{ children.tooltip || children.title }}</span>
                  </VBtn>
                </div>
              </VMenu>
            </template>
            <span class="tt-text-caption">{{ item.tooltip || item.title }}</span>
          </TTTooltip>
        </div>
      </template>
    </div>
    <template v-slot:append>
      <TTTooltip
        content-class="tt-module-menu-item-tooltip"
        nudge-right="4"
        right
        open-delay="300"
        fixed
        :disabled="drawer"
        transition="fade-transition"
      >
        <template v-slot:activator="{ on, attrs }">
          <VBtn
            v-if="!hideSignOut"
            v-bind="attrs"
            text
            width="64"
            height="48"
            class="tt-module-menu-sign-out"
            block
            :ripple="false"
            data-test="tt-btn"
            data-test-value="exit"
            v-on="on"
            @click="$emit('sign-out')"
          >
            <VIcon class="tt-module-menu-sign-out__icon">
              fal fa-sign-out-alt
            </VIcon>
            <div
              v-if="drawer"
              class="tt-text-caption ml-3 text-left"
              :style="titleWidth"
            >
              <div
                v-if="userName"
                class="text-truncate"
              >
                {{ userName }}
              </div>
              <div class="tt-light-red--text">
                {{ signOutText }}
              </div>
            </div>
          </VBtn>
        </template>
        <span class="tt-text-caption">{{ signOutText }}</span>
      </TTTooltip>
    </template>
  </VNavigationDrawer>
</template>

<script>
import BadgeMenu from './components/BadgeMenu.vue';
import TTTooltip from '../TTTooltip/TTTooltip.vue';

export default {
  name: 'TTModuleMenu',
  components: { BadgeMenu, TTTooltip },
  props: {
    itemsTree: {
      type: Array,
      default: () => [],
    },
    hideSignOut: {
      type: Boolean,
      default: false,
    },
    signOutText: {
      type: String,
      default: 'Выйти',
    },
    userName: {
      type: String,
      default: '',
    },
    dataTest: {
      type: String,
      default: 'tt-module-menu',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    maxWidth: {
      type: Number,
      default: 217,
    },
  },
  data() {
    return {
      drawer: false,
      minWidth: 81,
      childrenMenuIsShow: false,
      mouseOut: true,
    };
  },
  CLOSE_TIMER: null,
  CLOSE_DELAY: 100,
  computed: {
    menuItems() {
      const result = [...this.itemsTree];
      result.forEach((group) => {
        group.forEach((i) => {
          if (i.children && i.children.length > 0) {
            const item = i;
            item.isParent = true;
            if (!item.badge) item.badge = {};
            item.badge.value = item.children.some((c) => !!c.badge);
          }
        });
      });
      return result;
    },
    menuWidth() {
      const width = this.drawer ? this.maxWidth : this.minWidth;
      return `width: ${width}px;`;
    },
    titleWidth() {
      const width = this.maxWidth - 93;
      return `width: ${width}px;`;
    },
    mouseOver() {
      return !this.mouseOut || this.childrenMenuIsShow;
    },
  },
  watch: {
    mouseOver(v) {
      if (this.drawer) {
        this.setCloseTimer(v);
      }
    },
  },

  methods: {
    generateId(v) {
      return Symbol(v);
    },
    childrenMenuShowing(e) {
      this.childrenMenuIsShow = e;
    },
    setCloseTimer(v) {
      let timer = this.$options.CLOSE_TIMER;
      if (v) {
        clearTimeout(timer);
      } else {
        timer = setTimeout(() => { this.drawer = false; }, this.$options.CLOSE_DELAY);
      }
    },
    getActiveValue(item, value) {
      return item.isActive ?? value;
    },
  },
};
</script>

<style lang="scss">
.tt-module-menu-item-tooltip{
  padding: 8px 12px;
  font-size: 12px;
  line-height: 100%;
  &:before {
    content: "";
    position: absolute;
    left: -8px;
    top: 50%;
    transform: translateY(-50%);
    border: 4px solid;
    border-color: transparent rgba(map-get($tt-light-mono-100, 'base'), .9) transparent transparent;
  }
  .tt-text-caption{
    line-height: 100%;
  }
}
</style>

<style lang="scss" src="./TTModuleMenu.scss" scoped />
