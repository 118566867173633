import { methods } from '@/constants';
import * as endpoints from '@/services/backend/snami/constants';

export const appealRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_APPEAL,
    ...payload,
  }),

  resolve: (payload = {}) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_APPEAL_RESOLVE,
    ...payload,
  }),

  delete: (payload = {}) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_APPEAL_DELETE,
    ...payload,
  }),
});

export default appealRepository;
