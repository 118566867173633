import * as names from '@/plugins/vue-router/constants';

export const routesUser = [
  {
    path: '',
    name: names.R_USER,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesUser" */ '@/views/Users.vue'),
      'tt-view-right': () => import('@/components/app/AppUserAsideRight.vue'),
    },
  },
];
export default routesUser;
