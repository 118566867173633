import * as names from '@/plugins/vue-router/constants';
import isEnableMt from '@/plugins/vue-router/guards/isEnableMt';

export const routesContent = [
  {
    path: 'analytics',
    name: names.R_REPORT_CONTENT,
    meta: {
      middleware: [isEnableMt],
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "contentAnalytics" */ '@/views/Reports/ReportQuestions.vue'),
      'tt-view-right': () => import('@/components/app/AppAnalyticsAsideRight.vue'),
    },
  },
  {
    path: 'analytics/:id',
    name: names.R_REPORT_CONTENT_DETAIL,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "contentAnalyticsId" */ '@/views/Analytics/QuestionAnalytics.vue'),
      'tt-view-right': () => import('@/components/app/AppQuestionAnalyticsAsideRight.vue'),
    },
  },
  {
    path: ':id',
    name: names.R_CONTENT_LIST,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "contentList" */ '@/views/Content/ContentList.vue'),
      'tt-view-right': () => import('@/components/app/AppContentListAsideRight.vue'),
    },
  },
];
export default routesContent;
