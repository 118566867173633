<template>
  <div id="app">
    <VThemeProvider>
      <RouteNamesProvider>
        <RouterView />
      </RouteNamesProvider>
    </VThemeProvider>
  </div>
</template>

<script>
import RouteNamesProvider from '@/components/providers/RouteNamesProvider';

export default {
  name: 'App',
  components: {
    RouteNamesProvider,
  },
};
</script>

<style src="@fortawesome/fontawesome-pro/css/all.css"></style>
<style lang="scss" src="@/styles/main.scss"></style>

<style>
/*TODO: тень для v-menu hotfix!!*/
.v-menu-card{
  border: 1px solid #EBECED!important;
  box-shadow: 0 2px 24px rgba(11, 18, 24, 0.08)!important;
}
/*TODO: тень для v-menu hotfix!!*/
.v-menu-shadow {
  overflow: visible;
  contain: none;
}
</style>
