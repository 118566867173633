import { createAxiosTransport } from '@/services/api/_utils/createAxiosTransport';
import refresh from '@/services/api/_utils/interceptors/response/refreshToken';
import authentication from '@/services/api/_utils/interceptors/request/authentication';

const baseURL = process.env.VUE_APP_ONBOARDING_MT_API_ADDRESS || 'http://localhost:3000/';

const transport = createAxiosTransport({ baseURL });

transport.interceptors.request.use((config) => authentication(config), (error) => Promise.reject(error));
transport.interceptors.response.use((response) => response, (error) => refresh(error, transport));

export default transport;
