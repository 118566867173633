import { R_TAGS } from '@/plugins/vue-router/constants';

export const routesTags = [
  {
    path: '',
    name: R_TAGS,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesTags" */ '@/views/Tags/Tags.vue'),
    },
  },

];
export default routesTags;
