import Vue from 'vue';
import BottleJS from 'bottlejs';
import store from '@/plugins/vuex';
import { authFactory } from '@/services/api/openapi';
import FeatureFlag from '@/plugins/feature-flag';

const baseURL = process.env.VUE_APP_MULTITRACKS_AUTH_API_ADDRESS || 'http://localhost:3000';

const auth = authFactory({
  baseURL,
});

const bottle = new BottleJS();

let flags = [];

bottle.defer(() => {
  // Вызов перенесен в initStoreAction
  flags = store.getters['ff/flags'];
});

// bottle.resolve('flags');

const api = {
  auth,
};

bottle.factory('api', () => api);
bottle.factory('ff', () => new FeatureFlag(flags));

const install = (vue) => {
  Object.defineProperty(vue.prototype, '$di', {
    get() {
      return bottle.container;
    },
  });
};

Vue.use(install);

export default bottle;
