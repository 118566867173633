/* eslint-disable import/prefer-default-export */
import { create } from 'axios';
import toSnakeCaseRequestTransformer from '@/services/api/_utils/transformers/toSnakeCaseRequestTransformer';
import toCamelCaseResponseTransformer from '@/services/api/_utils/transformers/toCamelCaseResponseTransformer';

/*
 * @see: https://github.com/axios/axios#request-config
 */
function createAxiosTransport({ baseURL = 'http://localhost:3000', ...options } = {}) {
  const transport = create({
    baseURL,
    headers: {
      'Content-Type': 'application/json',
    },
    responseType: 'json',
    responseEncoding: 'utf8',
    ...options,
  });

  // IMPORTANT: стандартный трансформер запросов конвертит данные в строки,
  // поэтому сперва используй наш трансформер запросов для массивов и объектов
  transport.defaults.transformRequest.unshift(toSnakeCaseRequestTransformer);
  transport.defaults.transformResponse.push(toCamelCaseResponseTransformer);

  return transport;
}

export { createAxiosTransport };
