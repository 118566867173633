export default function routesWithPrefix(prefix, routes) {
  return routes.map((route) => {
    // eslint-disable-next-line no-param-reassign
    if (route.path.length > 0 && route.path !== '/') {
      // eslint-disable-next-line no-param-reassign
      route.path = `${prefix}/${route.path}`;
    } else if (route.path.length > 0 && route.path === '/') {
      // eslint-disable-next-line no-param-reassign
      route.path = `${prefix}${route.path};`;
    } else {
      // eslint-disable-next-line no-param-reassign
      route.path = `${prefix}${route.path}`;
    }
    return route;
  });
}
