import { di } from '@/plugins';

/* eslint-disable*/
export default async function isEnableMt({
    next,
  }) {
    if (di.container.ff.get('mt_menu')) {
      return next({ name: 'app.home' });
    }
    else return next();
  }
  