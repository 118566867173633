import * as names from '@/plugins/vue-router/constants';
import isEnableMt from '@/plugins/vue-router/guards/isEnableMt';

export const routesLocation = [
  {
    path: '',
    name: names.R_LOCATION,
    meta: {
      middleware: [isEnableMt],
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesLocation" */ '@/views/Location.vue'),
      'tt-view-right': () => import('@/components/app/AppLocationAsideRight.vue'),
    },
  },
];
export default routesLocation;
