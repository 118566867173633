import {
  snakeCase, mapKeys, mapValues, isObject, isArray,
} from 'lodash';

// на бэке есть ключи которые при трансформации не совпадют, кладем их туда
const READ_ONLY = [
  'content_base64',
];

function toSnakeCase(obj) {
  if (isArray(obj)) {
    return obj.map((i) => toSnakeCase(i));
  }

  if (isObject(obj)) {
    return mapValues(
      mapKeys(obj, (v, k) => (!k.startsWith('_') && !READ_ONLY.includes(k) ? snakeCase(k) : k)),
      (value) => toSnakeCase(value),
    );
  }

  return obj;
}

export default toSnakeCase;
