import * as names from '@/plugins/vue-router/constants';

export const routesHome = [
  {
    path: '',
    name: names.R_HOME,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesHome" */ '@/views/Home.vue'),
      'tt-view-right': () => import('@/components/app/AppDefaultAsideRight.vue'),
    },
  },
];
export default routesHome;
