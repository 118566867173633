import Vue from 'vue';

import TTView from '@views/TTView.vue';
import TTModuleMenu from '@uikit/components/TTModuleMenu/TTModuleMenu.vue';

import TTAlert from '@uikit/components/TTAlert/TTAlert';
import TTAppBar from '@uikit/components/TTAppBar.vue';
import TTAutoComplete from '@uikit/components/TTAutocomplete/TTAutocomplete.vue';
import TTBtn from '@uikit/components/TTBtn/TTBtn.vue';
import TTChip from '@uikit/components/TTChip/TTChip.vue';
import TTChipGroup from '@uikit/components/TTChipGroup/TTChipGroup.vue';
import TTDataTable from '@uikit/components/TTDataTable/TTDataTable.vue';
import TTNavigationDrawer from '@uikit/components/TTNavigationDrawer.vue';
import TTSelect from '@uikit/components/TTTSelect/TTSelect.vue';
import TTSnackbar from '@uikit/components/TTSnackbar.vue';
import TTTextField from '@uikit/components/TTTextField/TTTextField.vue';
import TTToolBar from '@uikit/components/TTToolbar.vue';
import TTToolBarTitle from '@uikit/components/TTToolbarTitle.vue';
import TTTextarea from '@uikit/components/TTTextarea/TTTextarea.vue';
import TTNotify from '@uikit/components/TTNotify/TTNotify';
import TTBreadcrumbs from '@uikit/components/TTBreadcrumbs/TTBreadcrumbs.vue';
import TTCheckbox from '@uikit/components/TTCheckbox/TTCheckbox';
import TTTooltip from '@uikit/components/TTTooltip/TTTooltip.vue';
import TTAvatarGroup from '@uikit/components/TTAvatarGroup/TTAvatarGroup.vue';

Vue.component('TTAlert', TTAlert);
Vue.component(TTAppBar.name, TTAppBar);
Vue.component(TTAutoComplete.name, TTAutoComplete);
Vue.component(TTBtn.name, TTBtn);
Vue.component('TTChip', TTChip);
Vue.component('TTChipGroup', TTChipGroup);
Vue.component(TTDataTable.name, TTDataTable);
Vue.component(TTModuleMenu.name, TTModuleMenu);
Vue.component(TTNavigationDrawer.name, TTNavigationDrawer);
Vue.component(TTSelect.name, TTSelect);
Vue.component(TTSnackbar.name, TTSnackbar);
Vue.component(TTTextField.name, TTTextField);
Vue.component(TTToolBar.name, TTToolBar);
Vue.component(TTToolBarTitle.name, TTToolBarTitle);
Vue.component(TTView.name, TTView);
Vue.component('TTNotify', TTNotify);
Vue.component('TTTextarea', TTTextarea);
Vue.component('TTBreadcrumbs', TTBreadcrumbs);
Vue.component('TTCheckbox', TTCheckbox);
Vue.component(TTTooltip.name, TTTooltip);
Vue.component('TTAvatarGroup', TTAvatarGroup);
