import { methods } from '@/constants';

const API_CUSTOMER_TAGS_LIST = '/v2/customer/tags';
const API_CUSTOMER_TAGS_CREATE = '/v2/customer/tag';
const API_CUSTOMER_TAGS_DELETE = (id) => `/v2/customer/tag/${id}`;
const API_CUSTOMER_TAG = (id) => `/v2/customer/tag/${id}`;

export const tagsRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_TAGS_LIST,
    ...payload,
  }),
  create: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TAGS_CREATE,
    ...payload,
  }),
  get: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_TAG(payload.data.id),
    ...payload,
  }),
  update: (payload = {}) => transport({
    method: methods.put,
    url: API_CUSTOMER_TAG(payload.data.id),
    ...payload,
  }),
  delete: (payload = {}) => transport({
    method: methods.delete,
    url: API_CUSTOMER_TAGS_DELETE(payload.data.id),
  }),
});

export default tagsRepository;
