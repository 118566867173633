/* AUTH */
export const API_CUSTOMER_RESET_PASSWORD = 'customer/password/reset';
export const API_CUSTOMER_LOGIN = '/customer/login/v2';
export const API_CUSTOMER_LOGIN_TWO_FACTOR = 'customer/login/two-factor';
export const API_CUSTOMER_SIGN_LINK = '/customer/sign-link';
/* AUTH-END */

/* CUSTOMER-CONFIG  ✅️ */
export const API_CUSTOMER_CONFIG_POLITICS = '/customer/app-config/get'; // ✅
export const API_CUSTOMER_CONFIG_POLITICS_UPDATE = '/customer/app-config/update';// ✅
export const API_CUSTOMER_CONFIG_STYLE_LIST = '/customer/scheme/list'; // ❎ нигде не вызывается метод ручки
export const API_CUSTOMER_INFO = '/customer/info'; // ✅
export const API_CUSTOMER_CONFIG = '/customer/get'; // ✅
export const API_CUSTOMER_CONFIG_UPDATE = '/customer/update';// ✅
export const API_CUSTOMER_DICTIONARY = '/customer/dictionary';// ✅
export const API_CUSTOMER_CITY = '/customer/city/list';// ✅

/* CUSTOMER-CONFIG-END */

// repositories.dashboard.index ✅
export const API_CUSTOMER_DASHBOARD = '/customer/dashboard';

// repositories.location - ✅
export const API_CUSTOMER_LOCATIONS = '/customer/location/list';
export const API_CUSTOMER_LOCATIONS_CREATE = '/customer/location/create';
export const API_CUSTOMER_LOCATIONS_UPDATE = '/customer/location/update';
export const API_CUSTOMER_LOCATIONS_DELETE = '/customer/location/delete';

// repositories.appeal - ✅
export const API_CUSTOMER_APPEAL = '/customer/appeal/list';
export const API_CUSTOMER_APPEAL_RESOLVE = '/customer/appeal/resolve';
export const API_CUSTOMER_APPEAL_DELETE = '/customer/appeal/delete';

// repositories.achievement ✅
export const API_CUSTOMER_ACHIEVEMENT = '/customer/achievement/list';
export const API_CUSTOMER_ACHIEVEMENT_REORDER = '/customer/achievement/reorder';
export const API_CUSTOMER_ACHIEVEMENT_UPDATE = '/customer/achievement/update';

// repositories.event ✅
export const API_CUSTOMER_EVENTS = '/customer/event/list';
export const API_CUSTOMER_EVENT_CREATE = '/customer/event/create';
export const API_CUSTOMER_EVENT_UPDATE = '/customer/event/update';
export const API_CUSTOMER_EVENT_DELETE = '/customer/event/delete';
export const API_CUSTOMER_EVENT_ANALYTICS = (eventId) => `/customer/event/${eventId}/rate/report`;

/* MAILING ✅ */
export const API_CUSTOMER_MAILING = '/customer/mailing/list';
export const API_CUSTOMER_MAILING_CREATE = '/customer/mailing/create';
export const API_CUSTOMER_MAILING_UPDATE = '/customer/mailing/update';
export const API_CUSTOMER_MAILING_DELETE = '/customer/mailing/delete';
export const API_CUSTOMER_MAILING_CONDITION = '/customer/content-mailing-condition';
/* MAILING - END */

/* STAFF ✅️ */
export const API_CUSTOMER_STAFF_CREATE_CHECK = '/customer/staff/create-check';
export const API_CUSTOMER_STAFF = '/customer/staff/list';
export const API_CUSTOMER_STAFF_DETAIL = (id) => `/customer/staff/${id}/detail`;
export const API_CUSTOMER_STAFF_CREATE = '/customer/staff/create';
export const API_CUSTOMER_STAFF_UPDATE = '/customer/staff/update';
export const API_CUSTOMER_STAFF_FIRE = '/customer/staff/fire';
export const API_CUSTOMER_STAFF_SELECT = 'customer/staff/select';
export const API_CUSTOMER_STAFF_REPLACE_NETWORK = 'customer/staff/network/replace';
export const API_CUSTOMER_STAFF_NETWORK = (staffId) => `customer/staff/${staffId}/network`;
export const API_CUSTOMER_STAFF_HAS_NETWORK = (staffId) => `customer/staff/${staffId}/has/network`;
/* STAFF - END */

/* TASK-CONTENT ✅ */
export const API_CUSTOMER_CONTENT_CREATE = '/customer/task-content/create';
export const API_CUSTOMER_CONTENT_UPDATE = '/customer/task-content/update';
export const API_CUSTOMER_CONTENT_DELETE = '/customer/task-content/delete';
export const API_CUSTOMER_CONTENT_REORDER = '/customer/task-content/reorder';
export const API_CUSTOMER_TASK_CONTENT = '/customer/task-content/list';
/* TASK-CONTENT-END ✅️ */

export const API_CUSTOMER_CONTENT_CONNECTION = '/customer/content/connection';

export const API_CUSTOMER_LEVEL = '/customer/level/list';
export const API_CUSTOMER_LEVEL_COPY = '/customer/level/copy';
export const API_CUSTOMER_LEVEL_CREATE = '/customer/level/create';
export const API_CUSTOMER_LEVEL_DELETE = '/customer/level/delete';
export const API_CUSTOMER_LEVEL_UPDATE = '/customer/level/update';
export const API_CUSTOMER_LEVEL_REORDER = '/customer/level/reorder';
export const API_CUSTOMER_CONTENT_SEARCH = 'customer/level/search';
/* TASK ✅ */
export const API_CUSTOMER_TASK_LIST = '/customer/task/list';
export const API_CUSTOMER_TASK_DELETE = '/customer/task/delete';
export const API_CUSTOMER_TASK_UPDATE = '/customer/task/update';
export const API_CUSTOMER_TASK_CREATE = '/customer/task/create';
export const API_CUSTOMER_TASK_MOVE = '/customer/task/move';
export const API_CUSTOMER_TASK_REORDER = '/customer/task/reorder';
export const API_CUSTOMER_TASK_COPY = '/customer/task/copy';
/* TASK-END */

/* REPORT ✅ */
export const API_CUSTOMER_REPORT_STAFF = '/customer/report/staff';
export const API_CUSTOMER_REPORT_EVENT = '/customer/report/event';
export const API_CUSTOMER_REPORT_PROGRESS = '/customer/report/progress';
export const API_CUSTOMER_REPORT_CHECKPOINT = '/customer/report/checkpoint';
export const API_CUSTOMER_CONTENT_REPORT_LIST = '/customer/report/task-content';
export const API_CUSTOMER_CONTENT_REPORT_DETAIL = '/customer/report/task-content/details';
/* REPORT-END */
export const API_CUSTOMER_REPORT_CHECKPOINT_COMPLETE = '/customer/checkpoint/complete';
export const API_CUSTOMER_CHECKPOINT_UPDATE = '/customer/checkpoint/update';
export const API_CUSTOMER_CHECKPOINT_LIST = '/customer/checkpoint/list';
export const API_CUSTOMER_CHECKPOINT_COMPLETE = '/customer/checkpoint/complete';

export const API_CUSTOMER_TUTOR_CHECKPOINT_LIST = '/customer/tutor/checkpoint/list';
export const API_CUSTOMER_TUTOR_CHECKPOINT_TEMPLATE_LIST = '/customer/tutor/checkpoint-tpl/list';
export const API_CUSTOMER_DELETE_TUTOR_CHECKPOINT_TEMPLATE = '/customer/tutor/checkpoint-tpl/delete';
export const API_CUSTOMER_CREATE_TUTOR_CHECKPOINT_TEMPLATE = '/customer/tutor/checkpoint-tpl/create';

export const API_CUSTOMER_CHECKPOINT_TEMPLATE_LIST = '/customer/checkpoint-tpl/list';
export const API_CUSTOMER_DELETE_CHECKPOINT_TEMPLATE = '/customer/checkpoint-tpl/delete';
export const API_CUSTOMER_UPDATE_TUTOR_CHECKPOINT_TEMPLATE = '/customer/tutor/checkpoint-tpl/update';
export const API_CUSTOMER_CREATE_CHECKPOINT_TEMPLATE = '/customer/checkpoint-tpl/create';
export const API_CUSTOMER_UPDATE_CHECKPOINT_TEMPLATE = '/customer/checkpoint-tpl/update';

export const API_CUSTOMER_USER_LIST = '/customer/user/list';
export const API_CUSTOMER_USER_DELETE = 'customer/user/delete';
export const API_CUSTOMER_USER_CREATE = 'customer/user/create';
export const API_CUSTOMER_USER_UPDATE = 'customer/user/update';
export const API_CUSTOMER_GET_USER = 'customer/user/get';

export const API_CUSTOMER_INVITE_SEND = '/customer/invite/send';

export const API_CUSTOMER_PURPOSE_RATE = '/customer/purpose/rate';

export const API_TOKEN_EXCHANGE = '/token/exchange';

export const API_VIDEO_UPLOAD_INIT = '/v2/customer/video/upload/init';
export const API_VIDEO_POSTER = (id) => `/v2/customer/video/${id}/poster`;
export const API_VIDEO_DELETE = (id) => `/v2/customer/video/${id}`;

export const API_REPORT_TASK_CONTENT = (id) => `/report/task-content/${id}`;
export const API_REPORT_CHECKPOINTS = (id, fromDate, tillDate) => `/report/checkpoints/${id}/${fromDate}/${tillDate}`;
export const API_REPORT_STAFF = (id) => `/report/staff/${id}`;
export const API_REPORT_PROGRESS = (id) => `/report/progress/${id}`;
export const API_REPORT_ALL_TASKS = (locationId) => `/report/all-tasks-content/${locationId}`;

// Control panel API Routes
export const API_CP_LICENCES = '/cp/customer/stat';
export const API_CP_CUSTOMER_LICENCES_USED = (id) => `/cp/customer/${id}/license/used`;
export const API_CP_CUSTOMER_LICENCES_UPDATE = (id) => `/cp/customer/${id}/license`;
