/* eslint-disable no-param-reassign */
import { statuses } from '@/constants';
import { repositoryFactory } from '@/services/backend/snami/repositories';

const customerRepository = repositoryFactory('customer');

const state = {
  customerInfo: {},
  status: statuses.initial,
  languages: [],
  config: null,
};

const getters = {
  customerInfo: (st) => st.customerInfo,
  isSupervisor: (st) => st.config?.is_supervisor,
  config: (st) => st.config,
  languages: (st) => Object.entries(st.languages)
    .map((item) => ({
      value: item[0],
      text: item[1],
    })),
  languageByKey: () => (key) => getters.languages.find((item) => item.value === key),
  unresolvedAppeal: (st) => st.customerInfo.unresolved_appeal_count,
  expiredCheckpoint: (st) => st.customerInfo.expired_checkpoint_count,
};

const actions = {
  async getCustomerInfo({ commit }) {
    try {
      const { data } = await customerRepository.getInfo();
      commit('M_SET_CUSTOMER_INFO', data);
    } catch (err) {
      console.error(err);
      commit('M_SET_CUSTOMER_INFO');
    }
  },
  async getCustomerDictionary({ commit }) {
    try {
      const { data } = await customerRepository.getCustomerDictionary({ data: { key: ['LANGUAGES'] } });
      commit('M_SET_CUSTOMER_DICTIONARY', data.data.languages);
    } catch (e) {
      console.error(e);
    }
  },
  async getCustomerConfig({ commit }) {
    try {
      const { data } = await customerRepository.getConfig({});
      commit('M_SET_CUSTOMER_CONFIG', data.data);
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  M_SET_CUSTOMER_INFO(st, { data }) {
    if (data) {
      st.customerInfo = data;
      st.status = statuses.success;
    } else {
      st.status = statuses.error;
    }
  },
  M_SET_CUSTOMER_DICTIONARY(st, data) {
    st.languages = data;
  },
  M_SET_CUSTOMER_CONFIG(st, data) {
    st.config = data;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
