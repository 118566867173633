import Vue from 'vue';
import VueRouter from 'vue-router';
import createRouter from '@/plugins/vue-router/router';
import routes from '@/plugins/vue-router/routes/routes.app';
import { store } from '@/plugins';
import middlewarePipeline from '@/plugins/vue-router/middleware/middlewarePipeline';

Vue.use(VueRouter);

const { BASE_URL } = process.env;

const router = createRouter({
  baseUrl: BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  const noMiddlewares = to.matched.every((route) => !(route.meta && route.meta.middleware));
  if (noMiddlewares) {
    return next();
  }
  const middleware = to.matched.filter((item) => item.meta.middleware).map((item) => item.meta.middleware).flat();
  const context = {
    to,
    from,
    next,
    store,
  };

  return middleware[0]({
    ...context,
    next: middlewarePipeline(context, middleware, 1),
  });
});

export * from '@/plugins/vue-router/constants';
export default router;
