import * as auth from '@/services/api/openapi/auth';

import { createAxiosTransport } from '@/services/api/_utils/createAxiosTransport';

// eslint-disable-next-line
export const authFactory = ({
  baseURL,
}: {
  baseURL: string;
}) => {
  const axiosTransport = createAxiosTransport({ baseURL });
  return {
    authentication: auth.AuthenticationApiFactory(undefined, baseURL, axiosTransport),
  };
};
