import { methods } from '@/constants';
import { API_CUSTOMER_DASHBOARD } from '@/services/backend/snami/constants';

export const dashboardRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_DASHBOARD,
    ...payload,
  }),
});

export default dashboardRepository;
