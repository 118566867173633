import transport from '@/services/backend/snami/transport';
import transportMt from '@/services/api/multitracks';
import { appealRepository } from '@/services/backend/snami/repositories/appeal';
import { locationRepository } from '@/services/backend/snami/repositories/location';
import { dashboardRepository } from '@/services/backend/snami/repositories/dashboard';
import { achievementRepository } from '@/services/backend/snami/repositories/achievement';
import { eventRepository } from '@/services/backend/snami/repositories/event';
import { changelogRepository } from '@/services/backend/snami/repositories/changelog';
import { adminChangelogRepository } from '@/services/backend/snami/repositories/adminChangelog';
import { customerRepository } from '@/services/backend/snami/repositories/customer';
import { taskContentRepository } from '@/services/backend/snami/repositories/taskContent';
import { mailingRepository } from '@/services/backend/snami/repositories/mailing';
import { taskRepository } from '@/services/backend/snami/repositories/task';
import { reportRepository } from '@/services/backend/snami/repositories/report';
import { staffRepository } from '@/services/backend/snami/repositories/staff';
import { tagsRepository } from '@/services/backend/snami/repositories/tags';

export const repositories = () => ({
  location: locationRepository(transport),
  dashboard: dashboardRepository(transport),
  achievement: achievementRepository(transport),
  appeal: appealRepository(transport),
  event: eventRepository(transport),
  customer: customerRepository(transport),
  changelog: changelogRepository(transport),
  adminChangelog: adminChangelogRepository(transport),
  mailing: mailingRepository(transport),
  taskContent: taskContentRepository(transport),
  staff: staffRepository(transport),
  report: reportRepository(transport),
  task: taskRepository(transport),
  tags: tagsRepository(transportMt),
});
/* Если в каком-то случае нужно получить конкретный репозиторий(Vuex, unit-test, ...etc) */
export const repositoryFactory = (name) => repositories()[name];

export default {
  repositories,
  repositoryFactory,
};
// eslint-disable-next-line import/prefer-default-export
// export const locationRepository = LocationRepository(transport);
