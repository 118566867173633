/* tslint:disable */
/* eslint-disable */
/**
 * Onboarding Auth API
 * Onboarding Auth API
 *
 * The version of the OpenAPI document: 3.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ErrorWithFields } from '../models';
// @ts-ignore
import { InviteLinkSentData } from '../models';
// @ts-ignore
import { LoginCode } from '../models';
// @ts-ignore
import { LogoutRequest } from '../models';
// @ts-ignore
import { ModelError } from '../models';
// @ts-ignore
import { OauthTokens } from '../models';
// @ts-ignore
import { PasswordReset } from '../models';
// @ts-ignore
import { RequestPasswordReset } from '../models';
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Autologin into location associated with provided secret key
         * @param {LoginCode} loginCode Code from invite link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autologinByKey: async (loginCode: LoginCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCode' is not null or undefined
            assertParamExists('autologinByKey', 'loginCode', loginCode)
            const localVarPath = `/autologin`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Login by key from invite url
         * @param {LoginCode} loginCode Code from invite link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByKey: async (loginCode: LoginCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'loginCode' is not null or undefined
            assertParamExists('loginByKey', 'loginCode', loginCode)
            const localVarPath = `/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Refresh token invalidation makes not possible to retrieve new access token, but do not makes current access token invalid. It is client\'s responsibility to destroy current access token after calling this method.  If parameter `all` is false refresh token must be provided via request body or cookie (cookie has higher priority). 
         * @summary Invalidate provided refresh token or all refresh tokens
         * @param {boolean} [all] Invalidate all refresh tokens associated with current user or customer.
         * @param {string} [refreshToken] Refresh token value. Used only if &#x60;all&#x60; is false 
         * @param {LogoutRequest} [logoutRequest] Refresh token should be invalidated. Required only if no cookie with refresh token is provided and parameter &#x60;all&#x60; is false. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: async (all?: boolean, refreshToken?: string, logoutRequest?: LogoutRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            // authentication oauth required
            // oauth required
            await setOAuthToObject(localVarHeaderParameter, "oauth", [], configuration)

            if (all !== undefined) {
                localVarQueryParameter['all'] = all;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(logoutRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Request invite link sending
         * @param {RequestPasswordReset} [requestPasswordReset] Data to request invite link sending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestInviteLink: async (requestPasswordReset?: RequestPasswordReset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/request_invite_link`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * By default the link will be sent via email, if email address is specified in user\'s credentials, otherwise the sms will be used. Specifying `\"prefer_transport\": \"sms\"` will change that behavior backwards - sms will be used if phone number specified and will send email only if  no phone number specified (email must exists in this case anyway). 
         * @summary Request password reset link
         * @param {RequestPasswordReset} requestPasswordReset User\&#39;s credentials to reset the password (any of).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword: async (requestPasswordReset: RequestPasswordReset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestPasswordReset' is not null or undefined
            assertParamExists('requestResetPassword', 'requestPasswordReset', requestPasswordReset)
            const localVarPath = `/reset_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestPasswordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Second factor authentication
         * @param {LoginCode} [loginCode] Code for two factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondFactor: async (loginCode?: LoginCode, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/two_factor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(loginCode, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Set new password via reset link
         * @param {PasswordReset} passwordReset New password set data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasswordOnReset: async (passwordReset: PasswordReset, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'passwordReset' is not null or undefined
            assertParamExists('setPasswordOnReset', 'passwordReset', passwordReset)
            const localVarPath = `/reset_password/set_password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordReset, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Autologin into location associated with provided secret key
         * @param {LoginCode} loginCode Code from invite link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async autologinByKey(loginCode: LoginCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.autologinByKey(loginCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Login by key from invite url
         * @param {LoginCode} loginCode Code from invite link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginByKey(loginCode: LoginCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginByKey(loginCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Refresh token invalidation makes not possible to retrieve new access token, but do not makes current access token invalid. It is client\'s responsibility to destroy current access token after calling this method.  If parameter `all` is false refresh token must be provided via request body or cookie (cookie has higher priority). 
         * @summary Invalidate provided refresh token or all refresh tokens
         * @param {boolean} [all] Invalidate all refresh tokens associated with current user or customer.
         * @param {string} [refreshToken] Refresh token value. Used only if &#x60;all&#x60; is false 
         * @param {LogoutRequest} [logoutRequest] Refresh token should be invalidated. Required only if no cookie with refresh token is provided and parameter &#x60;all&#x60; is false. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async logout(all?: boolean, refreshToken?: string, logoutRequest?: LogoutRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.logout(all, refreshToken, logoutRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Request invite link sending
         * @param {RequestPasswordReset} [requestPasswordReset] Data to request invite link sending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestInviteLink(requestPasswordReset?: RequestPasswordReset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InviteLinkSentData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestInviteLink(requestPasswordReset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * By default the link will be sent via email, if email address is specified in user\'s credentials, otherwise the sms will be used. Specifying `\"prefer_transport\": \"sms\"` will change that behavior backwards - sms will be used if phone number specified and will send email only if  no phone number specified (email must exists in this case anyway). 
         * @summary Request password reset link
         * @param {RequestPasswordReset} requestPasswordReset User\&#39;s credentials to reset the password (any of).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async requestResetPassword(requestPasswordReset: RequestPasswordReset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.requestResetPassword(requestPasswordReset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Second factor authentication
         * @param {LoginCode} [loginCode] Code for two factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async secondFactor(loginCode?: LoginCode, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OauthTokens>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.secondFactor(loginCode, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary Set new password via reset link
         * @param {PasswordReset} passwordReset New password set data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async setPasswordOnReset(passwordReset: PasswordReset, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.setPasswordOnReset(passwordReset, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthenticationApiFp(configuration)
    return {
        /**
         * 
         * @summary Autologin into location associated with provided secret key
         * @param {LoginCode} loginCode Code from invite link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        autologinByKey(loginCode: LoginCode, options?: any): AxiosPromise<OauthTokens> {
            return localVarFp.autologinByKey(loginCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Login by key from invite url
         * @param {LoginCode} loginCode Code from invite link
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginByKey(loginCode: LoginCode, options?: any): AxiosPromise<OauthTokens> {
            return localVarFp.loginByKey(loginCode, options).then((request) => request(axios, basePath));
        },
        /**
         * Refresh token invalidation makes not possible to retrieve new access token, but do not makes current access token invalid. It is client\'s responsibility to destroy current access token after calling this method.  If parameter `all` is false refresh token must be provided via request body or cookie (cookie has higher priority). 
         * @summary Invalidate provided refresh token or all refresh tokens
         * @param {boolean} [all] Invalidate all refresh tokens associated with current user or customer.
         * @param {string} [refreshToken] Refresh token value. Used only if &#x60;all&#x60; is false 
         * @param {LogoutRequest} [logoutRequest] Refresh token should be invalidated. Required only if no cookie with refresh token is provided and parameter &#x60;all&#x60; is false. 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(all?: boolean, refreshToken?: string, logoutRequest?: LogoutRequest, options?: any): AxiosPromise<void> {
            return localVarFp.logout(all, refreshToken, logoutRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Request invite link sending
         * @param {RequestPasswordReset} [requestPasswordReset] Data to request invite link sending
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestInviteLink(requestPasswordReset?: RequestPasswordReset, options?: any): AxiosPromise<InviteLinkSentData> {
            return localVarFp.requestInviteLink(requestPasswordReset, options).then((request) => request(axios, basePath));
        },
        /**
         * By default the link will be sent via email, if email address is specified in user\'s credentials, otherwise the sms will be used. Specifying `\"prefer_transport\": \"sms\"` will change that behavior backwards - sms will be used if phone number specified and will send email only if  no phone number specified (email must exists in this case anyway). 
         * @summary Request password reset link
         * @param {RequestPasswordReset} requestPasswordReset User\&#39;s credentials to reset the password (any of).
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        requestResetPassword(requestPasswordReset: RequestPasswordReset, options?: any): AxiosPromise<void> {
            return localVarFp.requestResetPassword(requestPasswordReset, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Second factor authentication
         * @param {LoginCode} [loginCode] Code for two factor authentication
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        secondFactor(loginCode?: LoginCode, options?: any): AxiosPromise<OauthTokens> {
            return localVarFp.secondFactor(loginCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Set new password via reset link
         * @param {PasswordReset} passwordReset New password set data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        setPasswordOnReset(passwordReset: PasswordReset, options?: any): AxiosPromise<void> {
            return localVarFp.setPasswordOnReset(passwordReset, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
    /**
     * 
     * @summary Autologin into location associated with provided secret key
     * @param {LoginCode} loginCode Code from invite link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public autologinByKey(loginCode: LoginCode, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).autologinByKey(loginCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Login by key from invite url
     * @param {LoginCode} loginCode Code from invite link
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public loginByKey(loginCode: LoginCode, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).loginByKey(loginCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Refresh token invalidation makes not possible to retrieve new access token, but do not makes current access token invalid. It is client\'s responsibility to destroy current access token after calling this method.  If parameter `all` is false refresh token must be provided via request body or cookie (cookie has higher priority). 
     * @summary Invalidate provided refresh token or all refresh tokens
     * @param {boolean} [all] Invalidate all refresh tokens associated with current user or customer.
     * @param {string} [refreshToken] Refresh token value. Used only if &#x60;all&#x60; is false 
     * @param {LogoutRequest} [logoutRequest] Refresh token should be invalidated. Required only if no cookie with refresh token is provided and parameter &#x60;all&#x60; is false. 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public logout(all?: boolean, refreshToken?: string, logoutRequest?: LogoutRequest, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).logout(all, refreshToken, logoutRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Request invite link sending
     * @param {RequestPasswordReset} [requestPasswordReset] Data to request invite link sending
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public requestInviteLink(requestPasswordReset?: RequestPasswordReset, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).requestInviteLink(requestPasswordReset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * By default the link will be sent via email, if email address is specified in user\'s credentials, otherwise the sms will be used. Specifying `\"prefer_transport\": \"sms\"` will change that behavior backwards - sms will be used if phone number specified and will send email only if  no phone number specified (email must exists in this case anyway). 
     * @summary Request password reset link
     * @param {RequestPasswordReset} requestPasswordReset User\&#39;s credentials to reset the password (any of).
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public requestResetPassword(requestPasswordReset: RequestPasswordReset, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).requestResetPassword(requestPasswordReset, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Second factor authentication
     * @param {LoginCode} [loginCode] Code for two factor authentication
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public secondFactor(loginCode?: LoginCode, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).secondFactor(loginCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Set new password via reset link
     * @param {PasswordReset} passwordReset New password set data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    public setPasswordOnReset(passwordReset: PasswordReset, options?: AxiosRequestConfig) {
        return AuthenticationApiFp(this.configuration).setPasswordOnReset(passwordReset, options).then((request) => request(this.axios, this.basePath));
    }
}
