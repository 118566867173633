/* eslint-disable */

import './dayjs';
import './ctk';
import './titify';
import './sentry';

export { default as di } from './di';
export { default as i18n } from '@/plugins/vue-i18n';
export { default as router } from '@/plugins/vue-router';
export { default as store } from '@/plugins/vuex';
export { default as vuetify } from '@/plugins/vuetify';
export { default as repositories } from '@/plugins/repositories';
export { default as usedesk } from '@/plugins/usedesk';
export { default as helpDesk } from '@/plugins/helpdesk';

