import { methods } from '@/constants';
import {
  API_CUSTOMER_MAILING,
  API_CUSTOMER_MAILING_CONDITION,
  API_CUSTOMER_MAILING_CREATE,
  API_CUSTOMER_MAILING_DELETE,
  API_CUSTOMER_MAILING_UPDATE,
} from '@/services/backend/snami/constants';

export const mailingRepository = (transport) => ({

  list: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_MAILING,
    ...payload,
  }),
  create: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_MAILING_CREATE,
    ...payload,
  }),
  update: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_MAILING_UPDATE,
    ...payload,
  }),

  delete: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_MAILING_DELETE,
    ...payload,
  }),

  getCondition: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_MAILING_CONDITION,
    ...payload,
  }),
});

export default mailingRepository;
