import { authToken } from '@/services';

export default async function authentication(config) {
  const token = authToken.get();
  if (token) {
    const headers = { ...config.headers };
    headers['X-Token'] = token;
    Object.assign(config.headers, headers);
  }

  return config;
}
