/* eslint-disable*/
export default async function isSupervisor({
  next,
  store,
}) {

  if (!store.getters['customerInfo/isSupervisor'] || !store.getters['acl/isGod']) {
    return next({ name: 'app.home' });
  }
  else return next();
}
/* eslint-enable */
