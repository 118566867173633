import { methods } from '@/constants';
import {
  API_CUSTOMER_STAFF,
  API_CUSTOMER_STAFF_CREATE,
  API_CUSTOMER_STAFF_CREATE_CHECK,
  API_CUSTOMER_STAFF_DETAIL,
  API_CUSTOMER_STAFF_FIRE,
  API_CUSTOMER_STAFF_HAS_NETWORK,
  API_CUSTOMER_STAFF_NETWORK,
  API_CUSTOMER_STAFF_REPLACE_NETWORK,
  API_CUSTOMER_STAFF_SELECT,
  API_CUSTOMER_STAFF_UPDATE,
} from '@/services/backend/snami/constants';

export const staffRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF,
    ...payload,
  }),
  get: (id) => transport({
    method: methods.get,
    url: API_CUSTOMER_STAFF_DETAIL(id),
  }),

  create: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF_CREATE,
    ...payload,
  }),
  update: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF_UPDATE,
    ...payload,
  }),

  select: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF_SELECT,
    ...payload,
  }),

  replaceNetwork: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF_REPLACE_NETWORK,
    ...payload,
  }),

  getNetwork: (id) => transport({
    method: methods.get,
    url: API_CUSTOMER_STAFF_NETWORK(id),
  }),

  hasNetwork: (id) => transport({
    method: methods.get,
    url: API_CUSTOMER_STAFF_HAS_NETWORK(id),
  }),

  fire: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF_FIRE,
    ...payload,
  }),

  createCheck: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_STAFF_CREATE_CHECK,
    ...payload,
  }),
});

export default staffRepository;
