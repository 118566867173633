import * as names from '@/plugins/vue-router/constants';

export const routesMeeting = [
  {
    path: 'tpl',
    name: names.R_MEETING_TPL_LIST,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesMeetingTemplateList" */ '@/views/Meeting/TemplateList.vue'),
      'tt-view-right': () => import('@/components/app/AppTemplateListAsideRight.vue'),
    },
  },
];
export default routesMeeting;
