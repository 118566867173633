import i18n from '@/plugins/vue-i18n';

export const foo = 'bar';

export const statuses = {
  initial: 'initial',
  inProgress: 'inProgress',
  success: 'success',
  error: 'error',
};

export const methods = {
  get: 'GET',
  post: 'POST',
  put: 'PUT',
  delete: 'DELETE',
};

export const roles = {
  STAFF: 'STAFF',
  MENTOR: 'MENTOR',
  TUTOR: 'TUTOR',
  HR: 'HR',
  NONE: 'NONE',
};

export const taskTypeTranslate = {
  NONE: '',
  WATCH: 'Смотреть',
  READ: 'Прочитай',
  TAKE: 'Возьми',
  LOCATION: 'Локация',
  TEST: 'Тест',
  TASK: 'Задача',
  MEET: 'Встреча',
  QRCODE: 'QR-код',
  OTHER: 'Другое',
  BUG: 'Баг',
  POOL: 'Опрос',
};

export const taskType = {
  NONE: 'NONE',
  WATCH: 'WATCH',
  READ: 'READ',
  TAKE: 'TAKE',
  LOCATION: 'LOCATION',
  TEST: 'TEST',
  TASK: 'TASK',
  MEET: 'MEET',
  QRCODE: 'QRCODE',
  OTHER: 'OTHER',
  BUG: 'BUG',
  POOL: 'POOL',
};

export const taskIconMap = {
  [taskType.WATCH]: 'fal fa-play-circle',
  [taskType.READ]: 'fal fa-book',
  [taskType.TAKE]: 'fal fa-hand-point-down',
  [taskType.LOCATION]: 'fal fa-map-marker-alt',
  [taskType.TEST]: 'fal fa-tasks',
  [taskType.TASK]: 'fal fa-glasses',
  [taskType.MEET]: 'fal fa-handshake',
  [taskType.QRCODE]: 'fal fa-qrcode',
  [taskType.OTHER]: 'fal fa-box',
  [taskType.BUG]: 'fal fa-bug',
  [taskType.POOL]: 'fal fa-bell',
  [taskType.NONE]: 'fal fa-box',
};

export const errorStatus = {
  0: 'Поле не может быть пустым',
  EMPTY: 'Поле не может быть пустым',
  REQUIRED: 'Обязательное поле',
  INVALID: 'Некорректное значение',
  LEN: 'Длина должна быть %d символов',
  DATE_YMD: 'Дата должна быть в формате ГГГГ-ММ-ДД',
  MIN: 'Минимальное значение: %d',
  MAX: 'Максимальное значение: %d',
  GT: 'Значение должно быть больше %d',
  GTE: 'Значение должно быть больше или равно %d',
  LT: 'Значение должно быть меньше %d',
  LTE: 'Значение должно быть меньше или равно %d',
  MIN_LEN: 'Длина должна быть больше %d символов',
  MAX_LEN: 'Длина должна быть меньше %d символов',
  TOO_FEW_ELEM: 'Минимальное количество элементов: %d',
  TOO_MANY_ELEM: 'Максимальное количество элементов: %d',
  REQUIRED_WITHOUT: 'Введите одно из полей',
  EMAIL: 'Недопустимый формат для email',
  EMAIL_LIST: 'Недопустимый формат для списка email',
};

export const commonError = {
  'Invalid JSON payload': 'Ошибка: некорректные данные',
  'Invalid request payload': '',
  BAD_REQUEST: '',
  'Entity already exists': 'Такой объект уже существует',
};

export const meetingStatus = {
  pending: 'PENDING',
  withoutProblem: 'WITHOUT_PROBLEM',
  withProblem: 'WITH_PROBLEM',
  failed: 'FAILED',
};

export const dictionaryKeys = {
  pushAction: 'PUSH_ACTION',
  checkpointTemplate: 'CHECKPOINT_TEMPLATE_INTERVAL',
  eventTaskType: 'EVENT_TASK_TYPE',
  levelTaskType: 'LEVEL_TASK_TYPE',
  visionTemplate: 'VISION_TEMPLATE',
  eventContentType: 'EVENT_CONTENT_TYPE',
  levelContentType: 'LEVEL_CONTENT_TYPE',
  chatContentType: 'CHAT_CONTENT_TYPE',
  fileType: 'FILE_TYPE',
  role: 'role',
};

export const purposeStates = {
  FAILED: 'FAILED',
  COMPLETED: 'COMPLETED',
  REJECTED: 'REJECTED',
  NEW: 'NEW',
  UNKNOWN: 'UNKNOWN',
};

export const purposeStateText = {
  [purposeStates.FAILED]: 'Не достигнута',
  [purposeStates.COMPLETED]: 'Достигнута',
  [purposeStates.REJECTED]: 'В архиве',
  [purposeStates.NEW]: 'В работе',
  [purposeStates.UNKNOWN]: 'Неизвестный статус',
};

export const staffStates = {
  ACTIVE: 'ACTIVE',
  INVITED: 'INVITED',
  ADAPTING: 'ADAPTING',
  ADAPTED: 'ADAPTED',
  FIRED: 'FIRED',
  WITHOUT_ADAPTATION: 'WITHOUT_ADAPTATION',
};

export const CallbackTypeMap = {
  PROFILE_PHOTO: 'Обновление аватара.',
  PROFILE_ABOUT: 'Обновить поле «Обо мне»',
};

export const contentTypeText = {
  HTML: 'Блок HTML',
  IMAGE: 'Изображение',
  DOCUMENT: 'Документ',
  QR_CODE: 'QR-код',
  VIDEO: 'Видео',
  MAP: 'Карта',
  ENTER_STRING: 'Ввод строки',
  ADD_PHOTO: 'Сделать фото',
  YES_NO: 'Вопрос да/нет',
  ONE_WORD: 'Кодовое слово',
  SELECT_ONE: 'Вопрос с одним вариантом ответа',
  SELECT_MANY: 'Вопрос с несколькими вариантами ответа',
  VOTE: 'Голосование',
  RATE: 'Оценить по шкале',
  OFFER: 'Предложение о работе',
  CONTACT: 'Контакт сотрудника',
};

export const smiles = [
  {
    activeIcon: 'fas fa-angry',
    icon: 'fal fa-angry',
    color: '#EF323F',
    text: 'Очень плохо',
    valueInt: 1,
    position: 'left',
  },
  {
    activeIcon: 'fas fa-frown',
    icon: 'fal fa-frown',
    color: '#FF3E00',
    text: 'Плохо',
    valueInt: 2,
    position: 'left',
  },
  {
    activeIcon: 'fas fa-meh',
    icon: 'fal fa-meh',
    color: '#FFB500',
    text: 'Нормально',
    valueInt: 3,
    position: 'center',
  },
  {
    activeIcon: 'fas fa-smile',
    icon: 'fal fa-smile',
    color: '#00D358',
    text: 'Хорошо',
    valueInt: 4,
    position: 'right',
  },
  {
    activeIcon: 'fas fa-laugh',
    icon: 'fal fa-laugh',
    color: '#00A345',
    text: 'Отлично',
    valueInt: 5,
    position: 'right',
  },

];
export const contentTypeIcons = {
  HTML: 'fal fa-code',
  IMAGE: 'fal fa-file-image',
  VIDEO: 'fal fa-video',
  DOCUMENT: 'fal fa-file-alt',
  YES_NO: 'fal fa-chart-pie-alt',
  SELECT_ONE: 'fal fa-clipboard-check',
  SELECT_MANY: 'fal fa-clipboard-list-check',
  VOTE: 'fal fa-chart-bar',
  RATE: 'fal fa-smile',
  CONTACT: 'fal fa-user-tie',
  MAP: 'fal fa-map-marker-alt',
  ENTER_STRING: 'fal fa-keyboard',
  ONE_WORD: 'fal fa-comment',
  ADD_PHOTO: 'fal fa-camera',
  QR_CODE: 'fal fa-qrcode',
  OFFER: 'fal fa-user-check',
};
export const eventType = {
  task: i18n.t('eventTypes.task'),
  notify: i18n.t('eventTypes.notify'),
  mailing: i18n.t('eventTypes.mailing'),
};

export const LS_NAME_SHOWED_USEDESK_HINT = 'showed_usedesk_hint';

export const levelSteps = [0, 1, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
