import localStorageInterface from '@/services/localStorage/_utils';
import { errorStatus, commonError } from '@/constants';

export const authToken = localStorageInterface('user_token');
export const exchangeToken = localStorageInterface('exchange-token');
export const pageLocationToken = localStorageInterface('snami_page_location');

export function validation(name) {
  const index = this.validationStatus.findIndex((i) => i.key === name);
  if (index > -1) {
    let errorCode;
    if (this.validationStatus[index].value.indexOf('=') !== -1) {
      errorCode = this.validationStatus[index].value.split('=');
    } else {
      errorCode = this.validationStatus[index].value.split(':');
    }
    let errorText = errorStatus[errorCode[0]] || this.validationStatus[index].value || errorStatus.REQUIRED;
    if (errorCode.length > 1) {
      errorText = errorText.replace('%d', errorCode[1]);
    }
    return {
      key: this.validationStatus[index].key,
      value: errorText,
      status: true,
    };
  }
  return { key: false, value: '', status: false };
}

export function handleError(error) {
  const { response } = error;
  if (response.status === 400 && response.data.error.code === 'BAD_REQUEST') {
    this.validationStatus = response.data.error.data || [];
    const { message } = response.data.error;
    if (commonError[message] !== undefined) {
      this.globalErrorMessage = commonError[message];
    } else {
      this.globalErrorMessage = message || '';
    }
    return false;
  }
  if (response.status === 409 && response.data.error.code === 'CONFLICT') {
    this.validationStatus = response.data.error.data || [];
    return false;
  }
  return console.warn(error);
}

export function watchValidationStatus() {
  if ((this?.validationStatus?.length || this?.globalErrorMessage) && this?.$el) {
    try {
      this.$nextTick(() => {
        const el = this.$el.getElementsByClassName('error--text');
        if (el.length) {
          const firstEl = el[0];
          if (firstEl?.parentNode && firstEl.parentNode.classList.contains('tt-input')) {
            firstEl.parentNode?.scrollIntoView();
          } else {
            firstEl?.scrollIntoView();
          }
        }
      });
    } catch (e) {
      console.error(e);
    }
  }
}

export const validateCustomRules = (rules, value) => {
  const errorBucket = [];
  for (let i = 0; i < rules.length; i += 1) {
    const rule = rules[i];
    const valid = typeof rule === 'function' ? rule(value) : rule;
    if (typeof valid === 'string') {
      errorBucket.push(valid);
    }
  }
  return errorBucket;
};

export const validateRules = {
  EMPTY: () => (v) => !!v || errorStatus.EMPTY,
  REQUIRED: () => (v) => !!v || errorStatus.REQUIRED,
  LEN: (payload) => (v) => (v && v.length === payload) || errorStatus.LEN.replace('%d', payload),
  MIN: (payload) => (v) => (v && v <= payload) || errorStatus.MIN.replace('%d', payload),
  MAX: (payload) => (v) => (v && v >= payload) || errorStatus.MAX.replace('%d', payload),
  GT: (payload) => (v) => (v && v > payload) || errorStatus.GT.replace('%d', payload),
  GTE: (payload) => (v) => (v && v >= payload) || errorStatus.GTE.replace('%d', payload),
  LT: (payload) => (v) => (v && v < payload) || errorStatus.LT.replace('%d', payload),
  LTE: (payload) => (v) => (v && v <= payload) || errorStatus.LTE.replace('%d', payload),
  MIN_LEN: (payload) => (v) => ((v && v.length >= payload) || !v) || errorStatus.MIN_LEN.replace('%d', payload),
  MAX_LEN: (payload) => (v) => ((v && v.length <= payload) || !v) || errorStatus.MAX_LEN.replace('%d', payload),
};
