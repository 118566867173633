/* eslint-disable no-param-reassign */
import Vue from 'vue';
import Vuex from 'vuex';
import modules from '@/plugins/vuex/modules';
import { LS_NAME_SHOWED_USEDESK_HINT } from '@/constants';

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    drawer: true,
    showUsedeskHint: false,
    flags: [],
  },
  getters: {
    drawer: (st) => st.drawer,
    showUsedeskHint: (state) => state.showUsedeskHint,

  },
  mutations: {
    M_SET_DRAWER(st, drawer) {
      st.drawer = drawer;
    },
    M_SET_SHOW_USEDESK_HINT(state, payload) {
      state.showUsedeskHint = payload;
      localStorage.setItem(LS_NAME_SHOWED_USEDESK_HINT, 1);
    },
  },
  actions: {
    async initStoreAction({ dispatch }) {
      try {
        // Запускаем в самом начале, чтобы собрать все фича флаги
        await dispatch('ff/getFlags');
      } catch (e) {
        console.error(e);
      }
      try {
        // Собираем информацию необходимую для проекта
        await Promise.all([
          dispatch('acl/getUser'),
          dispatch('customerInfo/getCustomerInfo'),
          dispatch('customerInfo/getCustomerConfig'),
          dispatch('customerInfo/getCustomerDictionary'),
          dispatch('changelog/getChangelog'),
        ]);
      } catch (e) {
        console.error(e);
      }
    },
    setShowUsedeskHint({ commit }, isShow) {
      if (!isShow || !localStorage.getItem(LS_NAME_SHOWED_USEDESK_HINT)) {
        commit('M_SET_SHOW_USEDESK_HINT', isShow);
      }
    },
  },
  modules,
});
