import * as names from '@/plugins/vue-router/constants';

export const routesAuth = [
  {
    path: '',
    name: names.R_AUTH_SIGN_IN,
    component: () => import(/* webpackChunkName: "sign-in" */ '@/views/Authentication/SignIn.vue'),
  },
  {
    path: ':login/:key',
    name: names.R_AUTH_SIGN_IN_TWO_FACTOR,
    component: () => import(/* webpackChunkName: "sign-in" */ '@/views/Authentication/SignInTwoFactor.vue'),
  },
];
export default routesAuth;
