import { authToken } from '@/services';
import { router } from '@/plugins';

let refreshingToken = null;

export default async function refresh(error, transport) {
  function refreshToken(token) {
    return transport.post('token/exchange', { token });
  }
  const originalConfig = error.config;
  const { response } = error;
  const token = authToken.get();
  // Если запрос был отбит из-за невалидного токена и запрос не повторный
  // eslint-disable-next-line no-underscore-dangle
  if (response && response.status === 401 && !originalConfig._retry) {
    // Если есть токен, пытаемся рефрешнуть
    if (token) {
      // eslint-disable-next-line no-underscore-dangle
      originalConfig._retry = true;
      try {
        refreshingToken = refreshingToken || refreshToken(token);
        const responseRefreshing = await refreshingToken;
        refreshingToken = null;
        if (responseRefreshing.data.data.token) {
          authToken.set(responseRefreshing.data.data.token);
        }
        return transport(originalConfig);
      } catch (e) {
        router.push({ name: 'auth.sign-in' });
        return Promise.reject(e);
      }
      //  Если токена нет, сразу на страницу авторизации кидаем
    } else {
      router.push({ name: 'auth.sign-in' });
      return Promise.reject(error);
    }
  }
  // Если ошибка не связана с токеном, то возвращаем ошибку
  return Promise.reject(error);
}
