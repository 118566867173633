import * as names from '@/plugins/vue-router/constants';

export const routesTracks = [
  {
    path: '',
    name: names.R_TRACKS,
    props: {
      default: (route) => ({ type: route.query.type }),
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesTracks" */ '@/views/Track/Tracks.vue'),
      'tt-view-right': () => import(/* webpackChunkName: "routesTracks" */ '@/components/app/AppTracksAsideRight.vue'),

    },
  },
  {
    path: 'create',
    name: names.R_TRACK_CREATE,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesTracksCreate" */ '@/views/Track/TrackManagement.vue'),
      // eslint-disable-next-line max-len,vue/max-len
      'tt-view-right': () => import(/* webpackChunkName: "routesTracks" */ '@/components/app/AppTrackManagementAsideRight.vue'),
    },
  },
  {
    path: ':id',
    name: names.R_TRACK,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesTracksId" */ '@/views/Track/TrackId.vue'),
      'tt-view-right': () => import(/* webpackChunkName: "routesTracks" */ '@/components/app/AppTrackAsideRight.vue'),
    },
  },
  {
    path: ':id/management',
    name: names.R_TRACK_MANAGEMENT,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesTracksIdManagement" */ '@/views/Track/TrackManagement.vue'),
      // eslint-disable-next-line max-len,vue/max-len
      'tt-view-right': () => import(/* webpackChunkName: "routesTracks" */ '@/components/app/AppTrackManagementAsideRight.vue'),
    },
  },
];
export default routesTracks;
