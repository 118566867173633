import * as names from '@/plugins/vue-router/constants';

export const routesStaff = [
  {
    path: '',
    name: names.R_STAFF,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesStaff" */ '@/views/Staff.vue'),
      'tt-view-right': () => import('@/components/app/AppStaffAsideRight.vue'),
    },
  },
  {
    path: ':id',
    name: names.R_STAFF_ITEM,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesStaffId" */ '@/views/Staff/StaffItem.vue'),
      'tt-view-right': () => import('@/components/app/AppStaffInfoAsideRight.vue'),
    },
  },
];
export default routesStaff;
