import axios from 'axios';
import apiRequestInterface from '@/services/api/index';
import { authToken } from '@/services';
import { router, store } from '@/plugins';
import FeatureFlag from '@/plugins/feature-flag';

// TODO: затащить норм транспорт .env backend service name

// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
const baseURLMT = process.env.VUE_APP_ONBOARDING_MT_API_ADDRESS || 'http://localhost:3000';

const config = {
  baseURL: process.env.VUE_APP_ONBOARDING_API_ADDRESS || 'http://localhost:3000',
  // timeout: 60 * 1000, // Timeout
  // withCredentials: true, // Check cross-site Access-Control
};

const transport = axios.create(config);
let ff;

/*
* Узнать зачем здесь фича флаги и выпилить в дальнейшем этот файл, в репозиториях и в любых других местах использовать
* новую реализацию транспорта из папки '@/services/api/main'
* Предварительно уделив особое внимание работе Response Interceptors - RefreshToken
*
* */
transport.interceptors.request.use(
  (cfg) => {
    ff = ff ?? new FeatureFlag(store.getters['ff/flags']);
    const conf = cfg;
    const token = authToken.get();
    if (token) {
      const headers = { ...cfg.headers };
      headers['X-Token'] = token;
      conf.headers = headers;
    }
    if (ff.get('api_multitracks')) {
      // TODO Подменяем baseURL на мультитрековый если включен фича флаг
      // по завершению работ с МТ перенести на 16 строку
      conf.baseURL = baseURLMT;
    }
    return conf;
  },
  (error) => Promise.reject(error),
);

function refreshToken(token) {
  return transport.post('token/exchange', { token });
}

let refreshingToken = null;

transport.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalConfig = error.config;
    const { response } = error;
    const token = authToken.get();
    // Если запрос был отбит из-за невалидного токена и запрос не повторный
    // eslint-disable-next-line no-underscore-dangle
    if (response && response.status === 401 && !originalConfig._retry) {
      // Если есть токен, пытаемся рефрешнуть
      if (token) {
        // eslint-disable-next-line no-underscore-dangle
        originalConfig._retry = true;
        try {
          refreshingToken = refreshingToken || refreshToken(token);
          const responseRefreshing = await refreshingToken;
          refreshingToken = null;
          if (responseRefreshing.data.data.token) {
            authToken.set(responseRefreshing.data.data.token);
          }
          return transport(originalConfig);
        } catch (e) {
          router.push({ name: 'auth.sign-in' });
          return Promise.reject(e);
        }
        //  Если токена нет, сразу на страницу авторизации кидаем
      } else {
        router.push({ name: 'auth.sign-in' });
        return Promise.reject(error);
      }
    }
    // Если ошибка не связана с токеном, то возвращаем ошибку
    return Promise.reject(error);
  },
);

const response = (resp) => Promise.resolve(resp);

const apiRequest = apiRequestInterface(transport, response);

export default apiRequest;
