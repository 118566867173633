/* eslint-disable no-param-reassign */
import { repositoryFactory } from '@/services/backend/snami/repositories';
import { dateToISO, isAfterDate, maxDateISO } from '@/utils/dateUtils';
import { changelogLocalStorage } from '@/services/localStorage';

const changelogRepository = repositoryFactory('changelog');
const state = () => ({
  changelog: [],
  dateOfRead: changelogLocalStorage.get(),
});

const getters = {
  lastDate: (st) => {
    if (!st.changelog.length) return null;
    return maxDateISO(st.changelog.filter((item) => !!item.pub_date)
      .map((item) => item.pub_date));
  },
  hasUnreadedData: (st, gt) => {
    if (!gt.lastDate) {
      return false;
    }
    if (gt.lastDate && !st.dateOfRead) {
      return true;
    }
    return isAfterDate(dateToISO(gt.lastDate), dateToISO(st.dateOfRead));
  },
};

const actions = {
  // eslint-disable-next-line no-shadow
  async getChangelog({ commit }) {
    try {
      const { data } = await changelogRepository.list();
      commit('SET_CHANGELOG', data);
    } catch (e) {
      console.error(e);
    }
  },
  async updateReadDate({ commit }) {
    try {
      const date = dateToISO();
      changelogLocalStorage.set(date);
      commit('SET_CHANGELOG_DATE_READ', date);
    } catch (e) {
      console.error(e);
    }
  },
};

const mutations = {
  SET_CHANGELOG(st, data) {
    st.changelog = data;
  },
  SET_CHANGELOG_DATE_READ(st, data) {
    st.dateOfRead = data;
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
