import transport from '@/services/backend/snami/transport';
import { methods } from '@/constants';
import * as endpoints from '@/services/backend/snami/constants';

export const resetPassword = (email) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_RESET_PASSWORD,
  data: { email },
});

export const customerLogin = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_LOGIN,
    data,
  });
};

export const customerLoginTwoFactor = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_LOGIN_TWO_FACTOR,
    data,
  });
};

export const signLink = (opts = {}) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_SIGN_LINK,
  data: opts,
});

export const saveLevel = ({ edit, data }) => transport({
  method: methods.post,
  url: edit ? endpoints.API_CUSTOMER_LEVEL_UPDATE : endpoints.API_CUSTOMER_LEVEL_CREATE,
  data,
});

export const getTaskList = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_TASK_LIST,
    data,
  });
};

export const deleteTask = (id) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_TASK_DELETE,
  data: { id },
});

export const moveTask = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_TASK_MOVE,
    data,
  });
};
export const saveTask = ({ edit, data }) => transport({
  method: methods.post,
  url: edit ? endpoints.API_CUSTOMER_TASK_UPDATE : endpoints.API_CUSTOMER_TASK_CREATE,
  data,
});
export const copyTask = (opts = {}) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_TASK_COPY,
  data: opts,
});

export const createCheck = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CREATE_CHECK,
    data,
  });
};

export const getContentConnections = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CONTENT_CONNECTION,
    data,
  });
};

export const getTutorCheckpointList = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_TUTOR_CHECKPOINT_LIST,
    data,
  });
};

export const getCheckpointList = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CHECKPOINT_LIST,
    data,
  });
};

export const getTutorCheckpointTemplateList = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_TUTOR_CHECKPOINT_TEMPLATE_LIST,
    data,
  });
};

export const deleteTutorCheckpointTemplate = (id) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_DELETE_TUTOR_CHECKPOINT_TEMPLATE,
  data: { id },
});

export const saveTutorCheckpointTemplate = ({ type, data }) => transport({
  method: methods.post,
  url: type === 'create' ? endpoints.API_CUSTOMER_CREATE_TUTOR_CHECKPOINT_TEMPLATE
    : endpoints.API_CUSTOMER_UPDATE_TUTOR_CHECKPOINT_TEMPLATE,
  data,
});

export const updateCheckpoint = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CHECKPOINT_UPDATE,
    data,
  });
};

export const completeCheckpoint = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CHECKPOINT_COMPLETE,
    data,
  });
};

export const getCheckpointTemplateList = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CHECKPOINT_TEMPLATE_LIST,
    data,
  });
};

export const deleteCheckpointTemplate = (id) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_DELETE_CHECKPOINT_TEMPLATE,
  data: { id },
});

export const saveCheckpointTemplate = ({ type, data }) => transport({
  method: methods.post,
  url: type === 'create' ? endpoints.API_CUSTOMER_CREATE_CHECKPOINT_TEMPLATE
    : endpoints.API_CUSTOMER_UPDATE_CHECKPOINT_TEMPLATE,
  data,
});

export const getLevel = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_LEVEL,
    data,
  });
};

export const copyLevel = (data) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_LEVEL_COPY,
  data,
});

export const deleteLevel = (id) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_LEVEL_DELETE,
  data: { id },
});

export const levelReorder = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_LEVEL_REORDER,
    data,
  });
};

export const customerInviteSend = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_INVITE_SEND,
    data,
  });
};

export const getUser = () => transport({
  method: methods.get,
  url: endpoints.API_CUSTOMER_GET_USER,
});

export const getUserList = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_USER_LIST,
    data,
  });
};

export const saveUser = ({ type, data }) => transport({
  method: methods.post,
  url: type === 'create' ? endpoints.API_CUSTOMER_USER_CREATE : endpoints.API_CUSTOMER_USER_UPDATE,
  data,
});

export const deleteUser = (id) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_USER_DELETE,
  data: { id },
});

export const contentSearch = (text) => transport({
  method: methods.post,
  url: endpoints.API_CUSTOMER_CONTENT_SEARCH,
  data: { text },
});

export const ratePurpose = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_PURPOSE_RATE,
    data,
  });
};

export const videoUploadInit = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.post,
    url: endpoints.API_VIDEO_UPLOAD_INIT,
    data,
  });
};

export const videoPoster = (id, file, type) => transport({
  method: methods.post,
  headers: {
    'Content-Type': type,
  },
  url: endpoints.API_VIDEO_POSTER(id),
  data: file,
});

export const videoDeletePoster = (id) => transport({
  method: methods.delete,
  url: endpoints.API_VIDEO_POSTER(id),
});

export const videoDelete = (id) => transport({
  method: methods.delete,
  url: endpoints.API_VIDEO_DELETE(id),
});

// Control Panel API
export const getCompanyLicences = (opts = {}) => {
  const data = opts;
  return transport({
    method: methods.get,
    url: endpoints.API_CP_LICENCES,
    data,
  });
};
export const getCustomerLicensesUsed = ({ id, data }) => transport({
  method: methods.get,
  url: endpoints.API_CP_CUSTOMER_LICENCES_USED(id),
  data,
});
export const updateCustomerLicenses = ({ id, data }) => transport({
  method: methods.post,
  url: endpoints.API_CP_CUSTOMER_LICENCES_UPDATE(id),
  data,
});

export const potokOauthCallback = (params) => transport({
  method: methods.get,
  url: '/v2/customer/potok/oauth/callback',
  params,
});

// THIS IS IN OTHER FILES ✅ (УДАЛИТЬ В СЛЕДУЮЩЕМ КОМИТЕ ПОСЛЕ ТЕСТОВ)
//
// // repositories.achievement.index
// export const getAchievements = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_ACHIEVEMENT,
//     data,
//   });
// };
// // repositories.achievement.reorder
// export const achievementsReorder = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_ACHIEVEMENT_REORDER,
//     data,
//   });
// };
// // repositories.achievement.update
// export const saveAchievements = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_ACHIEVEMENT_UPDATE,
//     data,
//   });
// };
//
// // repositories.event.create
// export const saveEvent = ({ type, data }) => transport({
//   method: methods.post,
//   url: type === 'create' ? endpoints.API_CUSTOMER_EVENT_CREATE : endpoints.API_CUSTOMER_EVENT_UPDATE,
//   data,
// });
//
// // repositories.event.delete
// export const deleteEvent = (id) => transport({
//   method: methods.post,
//   url: endpoints.API_CUSTOMER_EVENT_DELETE,
//   data: { id },
// });
// // repositories.event.index
// export const getEvent = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_EVENTS,
//     data,
//   });
// };
// // repositories.event.getAnalytic
// export const getEventAnalytics = (id) => transport({
//   method: methods.get,
//   url: endpoints.API_CUSTOMER_EVENT_ANALYTICS(id),
// });
//
// // repositories.dashboard.index
// export const getDashboard = (opts = {}) => transport({
//   method: methods.get,
//   url: endpoints.API_CUSTOMER_DASHBOARD,
//   params: opts,
// });
//
// // repositories.appeal.index
// export const getAppeal = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_APPEAL,
//     data,
//   });
// };
//
// // repositories.appeal.resolve
// export const resolveAppeal = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_APPEAL_RESOLVE,
//     data,
//   });
// };
// // repositories.appeal.delete
// export const deleteAppeal = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_APPEAL_DELETE,
//     data,
//   });
// };
//

// // repositories.customer
// export const getCity = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_CITY,
//     data,
//   });
// };
// // repositories.customer
// export const getCustomerInfo = () => transport({
//   method: methods.get,
//   url: endpoints.API_CUSTOMER_INFO,
// });
// // repositories.customer
// export const getCustomerDictionary = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_DICTIONARY,
//     data,
//   });
// };
// // repositories.customer
// export const getConfig = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.get,
//     url: endpoints.API_CUSTOMER_CONFIG,
//     data,
//   });
// };
// // repositories.customer
// export const updateConfig = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_CONFIG_UPDATE,
//     data,
//   });
// };
// // repositories.customer
// export const getConfigStyleList = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.get,
//     url: endpoints.API_CUSTOMER_CONFIG_STYLE_LIST,
//     data,
//   });
// };
// // repositories.customer.getConfigPolitics
// export const getConfigPolitics = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.get,
//     url: endpoints.API_CUSTOMER_CONFIG_POLITICS,
//     data,
//   });
// };
// // repositories.customer
// export const updatePoliticsConfig = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_CONFIG_POLITICS_UPDATE,
//     data,
//   });
// };
// // repositories.taskContent.save || update ✅
// export const saveContent = ({ type, data }) => transport({
//   method: methods.post,
//   url: type === 'create' ? endpoints.API_CUSTOMER_CONTENT_CREATE : endpoints.API_CUSTOMER_CONTENT_UPDATE,
//   data,
// });
// // repositories.taskContent.list ✅
// export const getTaskContent = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_TASK_CONTENT,
//     data,
//   });
// };
// // repositories.taskContent.delete ✅
// export const deleteTaskContent = (id) => transport({
//   method: methods.post,
//   url: endpoints.API_CUSTOMER_CONTENT_DELETE,
//   data: { id },
// });
// // repositories.taskContent.reorder✅
// export const taskContentReorder = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_CONTENT_REORDER,
//     data,
//   });
// };
// // repositories.mailing.list ✅
// export const getMailing = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_MAILING,
//     data,
//   });
// };
// // repositories.mailing.create || update ✅
// export const saveMailing = ({ type, data }) => transport({
//   method: methods.post,
//   url: type === 'create' ? endpoints.API_CUSTOMER_MAILING_CREATE : endpoints.API_CUSTOMER_MAILING_UPDATE,
//   data,
// });
// // repositories.mailing.delete ✅
// export const deleteMailing = (id) => transport({
//   method: methods.post,
//   url: endpoints.API_CUSTOMER_MAILING_DELETE,
//   data: { id },
// });
// // repositories.mailing.getCondition ✅
// export const getMailingCondition = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_MAILING_CONDITION,
//     data,
//   });
// };
// export const getStaff = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_STAFF,
//     data,
//   });
// };
//
// export const getStaffItem = (id) => transport({
//   method: methods.get,
//   url: endpoints.API_CUSTOMER_STAFF_DETAIL(id),
// });
//
// export const saveStaff = ({ type, data }) => transport({
//   method: methods.post,
//   url: type === 'create' ? endpoints.API_CUSTOMER_STAFF_CREATE : endpoints.API_CUSTOMER_STAFF_UPDATE,
//   data,
// });
//
// export const selectStaff = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_STAFF_SELECT,
//     data,
//   });
// };
//
// export const replaceStaffNetwork = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_STAFF_REPLACE_NETWORK,
//     data,
//   });
// };
//
// export const getStaffNetwork = (id) => transport({
//   method: methods.get,
//   url: endpoints.API_CUSTOMER_STAFF_NETWORK(id),
// });
//
// export const isStaffHasNetwork = (id) => transport({
//   method: methods.get,
//   url: endpoints.API_CUSTOMER_STAFF_HAS_NETWORK(id),
// });
//
// export const fireStaff = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_STAFF_FIRE,
//     data,
//   });
// };
//
// export const createCheck = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_STAFF_CREATE_CHECK,
//     data,
//   });
// };
//
// /* TASK ✅ */
// repositories.task.getTaskList ✅
// export const getTaskList = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_TASK_LIST,
//     data,
//   });
// };
//
// repositories.task.deleteTask ✅
// export const deleteTask = (id) => transport({
//   method: methods.post,
//   url: endpoints.API_CUSTOMER_TASK_DELETE,
//   data: { id },
// });
//
// repositories.task.moveTask ✅
// export const moveTask = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_TASK_MOVE,
//     data,
//   });
// };
// repositories.task.saveTask ||repositories.task.updateTask ✅
// export const saveTask = ({ edit, data }) => transport({
//   method: methods.post,
//   url: edit ? endpoints.API_CUSTOMER_TASK_UPDATE : endpoints.API_CUSTOMER_TASK_CREATE,
//   data,
// });
// repositories.task.copyTask ✅
// export const copyTask = (opts = {}) => transport({
//   method: methods.post,
//   url: endpoints.API_CUSTOMER_TASK_COPY,
//   data: opts,
// });
//
// repositories.task.taskReorder ✅
// export const taskReorder = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_TASK_REORDER,
//     data,
//   });
// };
// /* TASK END */
// repositories.report.getReportStaff ✅
// export const getReportStaff = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_REPORT_STAFF,
//     data,
//   });
// };
//
// repositories.report.getReportEvent ✅
// export const getReportEvent = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_REPORT_EVENT,
//     data,
//   });
// };
//
// repositories.report.getReportProgress ✅
// export const getReportProgress = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_REPORT_PROGRESS,
//     data,
//   });
// };
// repositories.report.getReportCheckpoint ✅
// export const getReportCheckpoint = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_REPORT_CHECKPOINT,
//     data,
//   });
// };
// repositories.report.getTaskContentReportList ✅
// export const getTaskContentReportList = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_CONTENT_REPORT_LIST,
//     data,
//   });
// };
//
// repositories.report.getTaskContentReportDetail ✅
// export const getTaskContentReportDetail = (opts = {}) => {
//   const data = opts;
//   return transport({
//     method: methods.post,
//     url: endpoints.API_CUSTOMER_CONTENT_REPORT_DETAIL,
//     data,
//   });
// };
/* REPORT END */
