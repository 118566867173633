import { methods } from '@/constants';
import {
  API_CUSTOMER_ACHIEVEMENT,
  API_CUSTOMER_ACHIEVEMENT_REORDER,
  API_CUSTOMER_ACHIEVEMENT_UPDATE,
} from '@/services/backend/snami/constants';

export const achievementRepository = (transport) => ({
  list: (payload) => transport({
    method: methods.post,
    url: API_CUSTOMER_ACHIEVEMENT,
    ...payload,
  }),

  reorder: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_ACHIEVEMENT_REORDER,
    ...payload,
  }),

  update: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_ACHIEVEMENT_UPDATE,
    ...payload,
  }),
});

export default achievementRepository;
