import * as names from '@/plugins/vue-router/constants';

export const routesAchievement = [

  {
    path: '',
    name: names.R_ACHIEVEMENT,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesAchievement" */ '@/views/Achievements/Index.vue'),
      'tt-view-right': () => import('@/components/app/AppDefaultAsideRight.vue'),
    },
  },
];
export default routesAchievement;
