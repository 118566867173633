import * as names from '@/plugins/vue-router/constants';

export const routesEvent = [
  {
    path: '',
    name: names.R_EVENTS,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesEventList" */ '@/views/Events.vue'),
      'tt-view-right': () => import('@/components/app/AppEventAsideRight.vue'),
    },
  },
  {
    path: ':locationId',
    name: names.R_EVENTS_ID,
    props: {
      default: true,
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesEventByLocationId" */ '@/views/Events.vue'),
      'tt-view-right': () => import('@/components/app/AppEventAsideRight.vue'),
    },
  },
  {
    path: 'content/:id',
    name: names.R_EVENT_CONTENT_LIST,
    props: {
      'tt-view-right': { showContentSearchButton: false },
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesEventContentList" */ '@/views/Content/ContentList.vue'),
      'tt-view-right': () => import('@/components/app/AppContentListAsideRight.vue'),
    },
  },
  {
    path: 'analytics/:id',
    name: names.R_EVENTS_ANALYTICS,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routeEventAnalytics" */ '@/views/Analytics/EventAnalytics.vue'),
      'tt-view-right': () => import('@/components/app/AppDefaultAsideRight.vue'),
    },
  },
];
export default routesEvent;
