import Vue from 'vue';
import {
  i18n, router, store, vuetify, repositories, di,
} from '@/plugins';
import App from '@/App.vue';

// TODO: использовать в компоненте
import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css';
import 'quill-emoji/dist/quill-emoji.css'; // quill-emoji

Vue.config.productionTip = false;

Vue.use(repositories);

(async () => {
  try {
  /* Инициализация стартовых данных для приложения решает проблемы некоторых дублированных запросов */
    await store.dispatch('initStoreAction');
  } catch (e) {
    console.warn(e);
  } finally {
    di.resolve('flags');
    new Vue({
      name: 'SNAMI',
      i18n,
      router,
      store,
      vuetify,
      render: (h) => h(App),
    }).$mount('#app');
  }
})();
