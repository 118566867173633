import * as names from '@/plugins/vue-router/constants';
import isSupervisor from '@/plugins/vue-router/guards/isSupervisor';

export const routesCp = [
  {
    path: '',
    name: names.R_CONTROL_PANEL,
    meta: {
      middleware: [isSupervisor],
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesControlPanelView" */
        '@/views/ControlPanel/ControlPanelView.vue'
      ),
      'tt-view-right': () => import('@/components/app/AppDefaultAsideRight.vue'),
    },
  },
  {
    path: 'changelog',
    name: names.R_CONTROL_CHANGELOG,
    meta: {
      middleware: [isSupervisor],
    },
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesControlPanelChangelog" */ '@/views/ControlPanel/Changelog.vue'),
      'tt-view-right': () => import('@/components/app/AppControlChangelogAsideRight.vue'),
    },
  },
];
export default routesCp;
