export const R_HOME = 'app.home';
export const R_STAFF = 'app.staff';
export const R_STAFF_ITEM = 'app.staffitem';
export const R_EVENTS = 'app.events';
export const R_EVENTS_ID = 'app.events.id';
export const R_EVENTS_ANALYTICS = 'app.events.analytics';
export const R_ABOUT = 'app.about';
export const R_POTOK_OAUTH = 'app.potok.oauth';
export const R_AUTH_SIGN_IN = 'auth.sign-in';
export const R_AUTH_SIGN_IN_TWO_FACTOR = 'auth.sign-in.twofactor';
export const R_NOT_FOUND = 'not-found';
export const R_LEVEL = 'app.level';
export const R_LEVEL_ID = 'app.level.id';
export const R_LOCATION = 'app.location';
export const R_USER = 'app.user';
export const R_APPEAL = 'app.appeal';
export const R_TASK_LEVEL = 'app.task.level';
export const R_CONTENT_LIST = 'app.content.list';
export const R_EVENT_CONTENT_LIST = 'app.event.content.list';
export const R_ACHIEVEMENT = 'app.achievement';
export const R_CONFIG = 'app.config';
export const R_CONTENT_SEARCH = 'app.content.search';
export const R_REPORT_CONTENT = 'app.content.analytics';
export const R_REPORT_CONTENT_DETAIL = 'app.content.analytics.detail';
export const R_REPORTS = 'app.reports';
export const R_REPORT_PROGRESS = 'app.report.progress';
export const R_REPORT_STAFF = 'app.report.staff';
export const R_REPORT_MEETING = 'app.report.meeting';
export const R_MEETING_TPL_LIST = 'app.meeting.tpl.list';
export const R_REPORT_TUTOR_LIST = 'app.report.tutor.list';
export const R_CONTROL_PANEL = 'app.control.panel';
export const R_CONTROL_CHANGELOG = 'app.control.changelog';
export const R_TRACKS = 'app.tracks';
export const R_TRACK = 'app.track';
export const R_TRACK_MANAGEMENT = 'app.track.management';
export const R_TRACK_CREATE = 'app.track.create';
export const R_GROUPS = 'app.groups';
export const R_GROUP = 'app.group';
export const R_GROUP_CREATE = 'app.group.create';
export const R_GROUP_MANAGEMENT = 'app.group.management';
export const R_TAGS = 'app.tags';
