/* eslint-disable*/
import { authToken } from '@/services';
import * as Names from '@/plugins/vue-router/constants';

export default function hasToken({ to, next }) {
  const { name } = to;
  const isLogged = authToken.get();
  if (!isLogged && name !== Names.R_AUTH_SIGN_IN) {
    return next({ name: Names.R_AUTH_SIGN_IN });
  }
  else{
    return next();
  }
}
