import * as names from '@/plugins/vue-router/constants';

export const routesSearch = [
  {
    path: '',
    name: names.R_CONTENT_SEARCH,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesSearch" */ '@/views/Search.vue'),
      'tt-view-right': () => import('@/components/app/AppDefaultAsideRight.vue'),
    },
  },
];
export default routesSearch;
