/* eslint-disable no-param-reassign */
import { statuses } from '@/constants';
import * as snamiApi from '@/services/backend/snami';

const state = {
  user: {},
  permit: [],
  role: [],
  status: statuses.initial,
};

const getters = {
  can: (st) => (required) => st.permit.indexOf(required) !== -1,
  user: (st) => st.user || {},
  isGod: (st) => {
    const roles = st.user?.role;
    if (!roles.length) {
      return false;
    }
    return roles.includes('god');
  },
  userId: (_, gtrs) => gtrs.user?.id,
};

const actions = {
  async getUser({ commit }) {
    try {
      const res = await snamiApi.getUser();
      commit('M_SET_USER', res.data);
    } catch (err) {
      console.error(err);
      commit('M_SET_USER');
    }
  },
};

const mutations = {
  M_SET_USER(st, { data }) {
    if (data) {
      st.user = data.user;
      st.role = data.role;
      st.permit = data.permit;
      st.status = statuses.success;
    } else {
      st.status = statuses.error;
    }
  },
};

export default {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};
