import { methods } from '@/constants';

const API_CUSTOMER_CHANGELOG = '/v2/customer/changelog';
const API_CUSTOMER_CHANGELOG_GET = (id) => `/v2/customer/changelog/${id}`;

export const changelogRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_CHANGELOG,
    ...payload,
  }),
  get: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_CHANGELOG_GET(payload.id),
  }),
});
export default changelogRepository;
