import hasToken from '@/plugins/vue-router/guards/hasToken';
import routesWithPrefix from '@/plugins/vue-router/utils/routesWithPrefix';
import { routesAuth } from '@/plugins/vue-router/routes/routes.auth';
import { routesReport } from '@/plugins/vue-router/routes/routes.report';
import { routesEvent } from '@/plugins/vue-router/routes/routes.event';
import { routesLevel } from '@/plugins/vue-router/routes/routes.level';
import { routesHome } from '@/plugins/vue-router/routes/routes.home';
import { routesStaff } from '@/plugins/vue-router/routes/routes.staff';
import { routesContent } from '@/plugins/vue-router/routes/routes.content';
import { routesMeeting } from '@/plugins/vue-router/routes/routes.meeting';
import { routesSearch } from '@/plugins/vue-router/routes/routes.search';
import { routesAbout } from '@/plugins/vue-router/routes/routes.about';
import { routesLocation } from '@/plugins/vue-router/routes/routes.location';
import { routesUser } from '@/plugins/vue-router/routes/routes.user';
import { routesAppeal } from '@/plugins/vue-router/routes/routes.appeal';
import { routesAchievement } from '@/plugins/vue-router/routes/routes.achievement';
import { routesConfig } from '@/plugins/vue-router/routes/routes.config';
import { routesCp } from '@/plugins/vue-router/routes/routes.cp';
import { routesPotok } from '@/plugins/vue-router/routes/routes.potok';
import { R_AUTH_SIGN_IN, R_HOME, R_NOT_FOUND } from '@/plugins/vue-router/constants';
import { routesTracks } from '@/plugins/vue-router/routes/routes.track';
import { routesGroups } from '@/plugins/vue-router/routes/routes.groups';
import { routesTags } from '@/plugins/vue-router/routes/routes.tags';

const routes = [
  {
    path: '/login',
    component: () => import('@/layouts/AuthenticationLayout.vue'),
    redirect: { name: R_AUTH_SIGN_IN },
    children: routesAuth,
  },
  {
    path: '/',
    component: () => import('@/layouts/HolyGrailLayout.vue'),
    meta: {
      middleware: [
        hasToken,
      ],
    },
    redirect: { name: R_HOME },
    children: [
      ...routesWithPrefix('home', routesHome),
      ...routesWithPrefix('staff', routesStaff),
      ...routesWithPrefix('reports', routesReport),
      ...routesWithPrefix('event', routesEvent),
      ...routesWithPrefix('level', routesLevel),
      ...routesWithPrefix('meeting', routesMeeting),
      ...routesWithPrefix('search', routesSearch),
      ...routesWithPrefix('content', routesContent),
      ...routesWithPrefix('about', routesAbout),
      ...routesWithPrefix('location', routesLocation),
      ...routesWithPrefix('user', routesUser),
      ...routesWithPrefix('appeal', routesAppeal),
      ...routesWithPrefix('achievement', routesAchievement),
      ...routesWithPrefix('config', routesConfig),
      ...routesWithPrefix('controlpanel', routesCp),
      ...routesWithPrefix('potok', routesPotok),
      ...routesWithPrefix('tracks', routesTracks),
      ...routesWithPrefix('groups', routesGroups),
      ...routesWithPrefix('tags', routesTags),
    ],
  },
  {
    path: '*',
    name: R_NOT_FOUND,
    component: () => import(/* webpackChunkName "not-found" */ '@/views/NotFound.vue'),
  },

];

export default routes;
