import { methods } from '@/constants';
import {
  API_CUSTOMER_CONFIG,
  API_CUSTOMER_CONFIG_POLITICS,
  API_CUSTOMER_CONFIG_POLITICS_UPDATE,
  API_CUSTOMER_CONFIG_STYLE_LIST,
  API_CUSTOMER_CONFIG_UPDATE,
  API_CUSTOMER_INFO,
} from '@/services/backend/snami/constants';
import * as endpoints from '@/services/backend/snami/constants';

export const customerRepository = (transport) => ({
  getInfo: () => transport({
    method: methods.get,
    url: API_CUSTOMER_INFO,
  }),

  getConfig: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_CONFIG,
    ...payload,
  }),

  updateConfig: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONFIG_UPDATE,
    ...payload,
  }),

  getConfigPolitics: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_CONFIG_POLITICS,
    ...payload,
  }),

  updatePoliticsConfig: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONFIG_POLITICS_UPDATE,
    ...payload,
  }),

  getConfigStyleList: (payload = {}) => transport({
    method: methods.get,
    url: API_CUSTOMER_CONFIG_STYLE_LIST,
    ...payload,
  }),

  getCustomerDictionary: (payload = {}) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_DICTIONARY,
    ...payload,
  }),

  getCity: (payload = {}) => transport({
    method: methods.post,
    url: endpoints.API_CUSTOMER_CITY,
    ...payload,
  }),
});

export default customerRepository;
