import * as names from '@/plugins/vue-router/constants';

export const routesConfig = [

  {
    path: '',
    name: names.R_CONFIG,
    components: {
      'aside-left': () => import('@/components/app/AppAsideLeftModuleMenu.vue'),
      default: () => import(/* webpackChunkName: "routesConfig" */ '@/views/Config.vue'),
      'tt-view-right': () => import('@/components/app/AppConfigAsideRight.vue'),
    },
  },
];
export default routesConfig;
