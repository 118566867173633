import { methods } from '@/constants';
import {
  API_CUSTOMER_CONTENT_CREATE,
  API_CUSTOMER_CONTENT_DELETE,
  API_CUSTOMER_CONTENT_REORDER,
  API_CUSTOMER_CONTENT_UPDATE, API_CUSTOMER_TASK_CONTENT,
} from '@/services/backend/snami/constants';

export const taskContentRepository = (transport) => ({
  list: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_TASK_CONTENT,
    ...payload,
  }),
  create: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONTENT_CREATE,
    ...payload,
  }),
  update: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONTENT_UPDATE,
    ...payload,
  }),

  delete: (payload) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONTENT_DELETE,
    ...payload,
  }),

  reorder: (payload = {}) => transport({
    method: methods.post,
    url: API_CUSTOMER_CONTENT_REORDER,
    ...payload,
  }),
});

export default taskContentRepository;
